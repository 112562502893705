import React from 'react'

import SERVICE_VEREDICT_TEMPLATE_INS01 from './templates/SERVICE_VEREDICT_TEMPLATE_INS01'
import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function ServiceVeredictDocument({ serviceVeredict }) {
	
	switch (serviceVeredict.format_version.format_template) {
		case 'SERVICE_VEREDICT_TEMPLATE_INS01':
			return <SERVICE_VEREDICT_TEMPLATE_INS01 data={serviceVeredict} />
	
		default:
			return <DocumentTemplate404 />
	}

}