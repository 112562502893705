import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'

import ReviewLogbookDetailsModal from './ReviewLogbookDetailsModal'

import { deleteReviewLogbook } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewLogbookTable({ reviewLogbooks, reload, serviceReview, serviceId }) {
	const { isConnected } = useSelector(state => state.offline)
    const user = useSelector(state => state.auth.user)
    const [logbookDetailsModal, setLogbookDetailsModal] = useState(null)
    const history = useHistory()

    const columns = [
        {
            title: 'Consecutivo',
            dataIndex: 'id',
            width: '100px',
            render: t => `BIT00${t}`
        },
        {
            title: 'Tipo',
            dataIndex: 'spec_type',
            render: t => t === 'tank' ? 'Tanque' : 'Tubería'
        },
        {
            title: 'Número',
            dataIndex: 'spec_number',
        },
        {
            title: 'Categoría',
            dataIndex: 'spec_category_slug',
        },
        {
            title: 'Producto',
            dataIndex: 'product_name',
            render: (t, r) => t === 'Otro' ? `${t} - ${r.product_other_name}` : t
        },
        {
            title: 'Acciones',
            render: (t, r) => (
                <React.Fragment>
                    <Tooltip 
                        title="Modificar Bitacora" 
                        className='text-link'
                        onClick={() => setLogbookDetailsModal(r)}
                    >
                        <FontAwesomeIcon icon={faEdit} />{" "}
                        Modificar Bitacora
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip 
                        title="Ver Formato de Bitacora" 
                        className='text-link'
                        onClick={() => history.push(isConnected ? `/review_logbooks/preview/${r.id}` : `/offline/review_logbooks/preview/${serviceId}/${r.id}`)}
                    >
                        <FontAwesomeIcon icon={faSearch} />{" "}
                        Ver Formato
                    </Tooltip>
                    { user.role === 'admin' && isConnected && (
                        <React.Fragment>
                            <Divider type="vertical" />
                            <Tooltip title="Eliminar" className='text-link'>
                                <Popconfirm
                                    title="Esta acción no se puede revertir"
                                    cancelText="Cancelar"
                                    okText="Eliminar"
                                    onConfirm={() => handleDelete(r.id)}
                                    okButtonProps={{ danger:true }}
                                >
                                <FontAwesomeIcon icon={faTrash} />{" "}
                                Eliminar
                                </Popconfirm>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
        }
    ]

    const handleDelete = id => {
        deleteReviewLogbook(id)
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error))
    }

    return (
        <React.Fragment>
            <Table 
                columns={columns}
                dataSource={reviewLogbooks}
                loading={!reviewLogbooks}
                rowKey={r => r.id}
                size="small"
                className='mb-10'
                locale={{ emptyText: (
                    <Empty description="No se han creado bitacoras aún" /> 
                )}}
            />
            { logbookDetailsModal && (
                <ReviewLogbookDetailsModal 
                    visible
                    onClose={() => setLogbookDetailsModal(null)}
                    defaultReviewLogbook={logbookDetailsModal}
                    serviceId={serviceId}
                />
            )}
        </React.Fragment>
    )
}