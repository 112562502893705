import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function ReviewLogbookSurveyAnswersForm({ register, errors, watch, specType, specCategory }) {

    switch (specType) {
        case 'tank':
            return (
                <React.Fragment>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Ha tenido durante el último inventario algún registro de pérdida de volumen?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.perdida_volumen', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.perdida_volumen_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Se han realizado modificaciones al cuerpo del tanque o a la instalación de combustible?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.modificaciones_cuerpo', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.modificaciones_cuerpo_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Ha presentado algún ingreso constante de agua?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.ingreso_agua', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.ingreso_agua_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Existe TE en tubería de desfogue?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.tuberia_desfogue', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.tuberia_desfogue_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Se prueba tubería de desfogue?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.prueba_tuberia_desfoge', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.prueba_tuberia_desfoge_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Se tapona la TE?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.tapona_te', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.tapona_te_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>Tipo de llenado</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.tipo_llenado', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Directo</option>
                                <option>Remoto</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Tiene Spill container?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.spill_container', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.spill_container_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Se Prueba tubería de llenado?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.prueba_llenado', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.prueba_llenado_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿Tiene sonda de medición?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.sonda_medicion', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.sonda_medicion_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>Se instala equipo de prueba:</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.equipo_prueba', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Medida</option>
                                <option>Llenado Remoto</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>Se verifica cierre de válvula de bomba sumergible</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.cierre_valvula', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.cierre_valvula_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="4">
                            <Form.Label>¿El Cliente desactivo la bomba sumergible?</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.descativo_bomba', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.descativo_bomba_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                </React.Fragment>
            )

        case 'pipeline':
            return (
                <React.Fragment>
                    { specCategory === 'dispensadores' && (
                        <Form.Group as={Row}>
                            <Col sm="4">
                                <Form.Label>Verificación de cierre de válvula de bomba sumergible</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    as="select"
                                    {...register('survey_answers.verifica_cierre', {})}
                                >
                                    <option value="">:: Selecciona una opción ::</option>
                                    <option>Sí</option>
                                    <option>N/A</option>
                                </Form.Control>
                                <Form.Control 
                                    as="textarea"
                                    {...register('survey_answers.verifica_cierre_obs', {})}
                                    placeholder='Escriba las observaciones'
                                    style={{ height: '70px' }}
                                />
                            </Col>
                        </Form.Group>
                    )}
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>Verificación de activación de válvula de impacto</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.verifica_activacion', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>N/A</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.verifica_activacion_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>Verificación de tubería desconectada y taponada </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                {...register('survey_answers.verifica_desconectada', {})}
                            >
                                <option value="">:: Selecciona una opción ::</option>
                                <option>Sí</option>
                                <option>N/A</option>
                            </Form.Control>
                            <Form.Control 
                                as="textarea"
                                {...register('survey_answers.verifica_desconectada_obs', {})}
                                placeholder='Escriba las observaciones'
                                style={{ height: '70px' }}
                            />
                        </Col>
                    </Form.Group>
                </React.Fragment>
            )
    
        default:
            return null;
    }

    
}