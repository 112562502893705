import React from 'react'
import styled from 'styled-components'
import { Descriptions } from 'antd'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'
import NcTaskCard from '../NcTaskCard'

const { Item } = Descriptions

export default function NON_COMPLIANT_REPORT_TEMPLATE_INS01({ data }) {
	const serviceInspector = data.service.service_inspector || null
	const user = serviceInspector.user || null
	const branch = data.service.proposal.inquiry.branch_spec.branch || null
	const employee = data.service.proposal.inquiry.employee || null
	const nonCompliantReport = serviceInspector.service_plan.service_review.non_compliant_report

	const ncTasks = data.verification_tasks.filter( task => (task.status.includes('NC -') || task.observations_retry))
	const ncpTasks = data.verification_tasks.filter( task => task.status.includes('NCP -'))

	return (
		<Document>
			<DocumentHeader 
				title="REPORTE DE NO CONFORMIDADES"
				version={nonCompliantReport.format_version.sku}
				expireAt={nonCompliantReport.format_version.expire_at}
				proposalSku={data.proposal_sku}
				logoSrc={logo}
			/>
			<Descriptions bordered column={1} size='small'>
				<Item label="N° Asignación inspección:">{serviceInspector && serviceInspector.sku}</Item>
				<Item label="Nombre de Instalación:">{branch && branch.name}</Item>
				<Item label="Nombre del funcionario que atiende la inspección:">{employee && employee.name}</Item>
				<Item label="Nombre del inspector:">{user && user.name}</Item>
				<Item label="Correo electronico del inspector:">{user && user.email}</Item>
			</Descriptions>
			<h6 className='mt-20'>1. NO CONFORMIDADES</h6>
			{ ncTasks.map( (task, index) => (
				<NcTaskCard task={task} index={index} key={task.id} />
			) )}
			<h6 className='mt-20'>2. NO CONFORMIDADES CON PLAZO</h6>
			{ ncpTasks.map( (task, index) => (
				<NcTaskCard task={task} key={task.id} ncp index={index} />
			) )}
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	table {
		margin-top: 15px;
	}
`