export const SYNC_SERVICE = 'services/SYNC'
export const REMOVE_SERVICE = 'services/REMOVE'
export const UPDATE_SERVICE_REVIEW = 'services/UPDATE_SERVICE_REVIEW'
export const UPDATE_VERIFICATION_TASKS = 'services/UPDATE_VERIFICATION_TASKS'
export const UPDATE_REVIEW_LOGBOOK = 'services/UPDATE_REVIEW_LOGBOOK'
export const DELETE_VERIFICATION_TASK = 'services/DELETE_VERIFICATION_TASK'

export const syncService = data => ({
	type: SYNC_SERVICE,
	payload: data
})

export const removeService = id => ({
	type: REMOVE_SERVICE,
	payload: id
})

export const offlineUpdateReview = (data, serviceId) => ({
	type: UPDATE_SERVICE_REVIEW,
	payload: data,
	serviceId
})

export const offlineUpdateTasks = (data, serviceId) => ({
	type: UPDATE_VERIFICATION_TASKS,
	payload: data,
	serviceId
})

export const offlineDeleteTask = (data, serviceId) => ({
	type: DELETE_VERIFICATION_TASK,
	payload: data,
	serviceId
})

export const offlineUpdateReviewLogbook = (data, serviceId, reviewLogbookId) => ({
	type: UPDATE_REVIEW_LOGBOOK,
	payload: data,
	serviceId,
	reviewLogbookId
})