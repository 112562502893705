import React from 'react'

import REVIEW_LOGBOOK_TEMPLATE_PRU01 from './templates/REVIEW_LOGBOOK_TEMPLATE_PRU01';
import REVIEW_LOGBOOK_TEMPLATE_PRU02 from './templates/REVIEW_LOGBOOK_TEMPLATE_PRU02';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ReviewLogbookDocument({ reviewLogbook }) {
    
    switch (reviewLogbook.format_version.format_template) {
        case 'REVIEW_LOGBOOK_TEMPLATE_PRU01':
            return <REVIEW_LOGBOOK_TEMPLATE_PRU01 reviewLogbook={reviewLogbook} />

        case 'REVIEW_LOGBOOK_TEMPLATE_PRU02':
            return <REVIEW_LOGBOOK_TEMPLATE_PRU02 reviewLogbook={reviewLogbook} />
    
        default:
            return <DocumentTemplate404 />
    }

}