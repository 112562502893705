import React from 'react'

import SERVICE_INSPECTOR_TEMPLATE_INS01 from './templates/SERVICE_INSPECTOR_TEMPLATE_INS01';
import SERVICE_INSPECTOR_TEMPLATE_PRU01 from './templates/SERVICE_INSPECTOR_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ServiceInspectorDocument({ serviceInspector }) {
	
	switch (serviceInspector.format_version.format_template) {
		case 'SERVICE_INSPECTOR_TEMPLATE_INS01':
			return <SERVICE_INSPECTOR_TEMPLATE_INS01 data={serviceInspector} />

		case 'SERVICE_INSPECTOR_TEMPLATE_PRU01':
			return <SERVICE_INSPECTOR_TEMPLATE_PRU01 data={serviceInspector} />
	
		default:
			return <DocumentTemplate404 />
	}

}