import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import NewInsCertificateForm from './forms/NewInsCertificateForm'
import NewTestCertificateForm from './forms/NewTestCertificateForm'

export default function NewCertificateModal({ visible, onClose, businessScope , serviceVeredictId, service, reload }) {

	const formProps = {
		serviceVeredictId: serviceVeredictId,
		criteria: service.criteria,
		branch: service.proposal.inquiry.branch_spec.branch,
		reload: reload,
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Generar Nuevo Certificado</ModalHeader>
				<ModalBody>
					{ businessScope === 'inspeccion' && (
						<NewInsCertificateForm {...formProps} />
					)}
					{ businessScope === 'pruebas' && (
						<NewTestCertificateForm {...formProps} />
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}