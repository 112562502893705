import React from 'react'
import styled from 'styled-components'
import { Descriptions } from 'antd'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'

const { Item } = Descriptions

export default function SERVICE_NOTIFICATION_TEMPLATE_INS01({ data }) {
	return (
		<Document>
			<DocumentHeader 
				title="NOTIFICACIÓN DE INSPECCIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
			<Descriptions column={2} bordered size='small'>
				<Item span={2} label="Inspector Asignado">{data.service_inspector.user.name}</Item>
				<Item label="Correo Electrónico">{data.service_inspector.user.email}</Item>
				<Item label="Celular">{data.service_inspector.user.mobile}</Item>
				<Item label="Fecha Envío Notificación">{moment(data.notified_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
				<Item label="Código Proceso">{data.service_inspector.service.proposal.sku}</Item>
			</Descriptions>
			<h6>1. INFORMACIÓN DE LA INSTALACIÓN</h6>
			<Descriptions column={2} bordered size='small'>
				<Item label="Razón Social">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.company.name}</Item>
				<Item label="NIT">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.company.complete_document}</Item>
				<Item label="Representante Legal" span={2}>{data.service_inspector.service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}</Item>
				<Item label="Nombre de Instalación">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.name}</Item>
				<Item label="Tipo de Instalación">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.instalation_type_name}</Item>
				<Item span={2} label="Correo Electrónico">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.email}</Item>
				<Item label="Dirección">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_line}</Item>
				<Item label="Ciudad">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_state}, {data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_city}</Item>
				<Item label="Teléfono">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.phone}</Item>
				<Item label="Celular">{data.service_inspector.service.proposal.inquiry.branch_spec.branch.mobile}</Item>
			</Descriptions>
			<h6>2. INFORMACIÓN DE LA INSPECCIÓN</h6>
			<Descriptions column={3} bordered size='small'>
				<Item label="Tipo de Inspección" span={3}>{data.service_inspector.service.type}</Item>
				<Item label="Criterio" span={3}>{data.service_inspector.service.criteria}</Item>
				<Item label="Lugar">Sede de la instalación a inspeccionar</Item>
				<Item label="Horas Aprox.">{data.service_inspector.service.hours_estimate}</Item>
				<Item label="Fecha">{moment(data.service_inspector.service.start_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
				<Item span={3} label="Observaciones/ Observadores*">
					{data.service_inspector.service.observers}
				</Item>
			</Descriptions>
			<small className="mt-15">* Acompañaran las actividades de inspección, pero no es parte de las mismas, y no influencian ni interfieren con la realización de la inspección ni los hallazgos ni conclusiones de la misma</small>
			<ul className='mt-20'>
				<li>El tiempo programado para la ejecución de la inspección está de terminado por el alcance y la especificaciones de la instalación, es un tiempo aproximado el cual se detallará en el Plan de inspección enviado por el inspección previo al día de la actividad de inspección en la instalación.</li>
				<li>Durante el tiempo de inspección es requerido que el Cliente tenga disponible personal encargado de atender la inspección y autorizado para el manejo de los equipos, suministrar información y documentación requerida para la inspección.</li>
				<li>A esta notificación se adjunta el Anexo 1. Documentos requeridos para la Instalación, corresponde a la relación de los documentos mínimos que la instalación debe tener disponibles para la inspección inicial. Cuando la actividad corresponde a inspección de cierre, los documentos que se requieren serán los que soporten la atención de los hallazgos encontrados en la inspección inicial.</li>
				<li>Los cambios a la notificación deben solicitarse por correo electrónico a la coordinación de programación: programacion@dewar.com.co, siempre y cuando este en cumplimiento al Reglamento de inspección DN5 y procedimiento de inspección PN2..</li>
				<li><strong>Si pasados tres (3) días de recibida esta notificación no se ha comunicado ninguna solicitud de modificación se considera que es aceptada y se desarrollará según lo establecido.</strong></li>
				<li>Recuerde que bajo solicitud, tenemos disponible procedimientos de DEWAR, para las actividades de inspección, así como de quejas y apelaciones, entre otros.</li>
			</ul>
			<p>Atentamente,</p>
			<p>{ data.sign_by }</p>
			<p className='mb-20'>Coordinadora de programación</p>
			<p>DEWAR – OIN</p>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 30px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
`