import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import ReportDecisionDetails from '../ServiceReports/partials/ReportDecisionDetails'
import EditServiceReportModal from '../ServiceReports/partials/EditServiceReportModal'
import NewServiceVeredictModal from './partials/NewServiceVeredictModal'

export default function ServiceVeredictTab({ serviceReport, reload }) {
	const currentUserRole = useSelector(state => state.auth.user.role)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditServiceReportModal, setShowEditServiceReportModal] = useState(false)

	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Revisión y Decisión</h6>
			{ serviceReport.service_veredict ? (
				<React.Fragment>
					<ReportDecisionDetails 
						serviceReport={serviceReport} 
						reload={reload}
						editable={serviceReport.is_editable}
					/>
					{ serviceReport.format_version.business_scope === 'inspeccion' && (
						<React.Fragment>
							<Link to={`/service_veredicts/preview/${serviceReport.service_veredict.id}`}>
								<Button color="primary" outline className='mt-10'>
									Formato Revisión y Decisión
								</Button>
							</Link>
							<Divider type="vertical" />
							{ currentUserRole === 'admin' && (
								<Button 
									color="primary" 
									outline 
									className='mt-10' 
									onClick={() => setShowEditServiceReportModal(true)}
								>
									Editar Resultados
								</Button>
							)}
						</React.Fragment>
					)}
				</React.Fragment>	
			) : (
				<Empty description="No se ha iniciado la revisión y decisión aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Iniciar Revisión y Decisión
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewServiceVeredictModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceReport={serviceReport}
					reload={reload}
				/>
			)}
			{ showEditServiceReportModal && (
				<EditServiceReportModal
					visible
					onClose={() => setShowEditServiceReportModal(false)}
					serviceReport={serviceReport}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}