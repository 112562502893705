import React from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'

import logo from '../assets/images/logo.png'

export default function DocumentHeader({ title, version, expireAt, proposalSku = '', elaboratedAt, logoSrc }) {
	return (
		<React.Fragment>
			<Row className='px-7 mb-0'>
				<Col className='pt-10'>
					<img src={logoSrc ? logoSrc : logo} alt="Logo Dewar" width={logoSrc ? '220px' : '180px'} />
				</Col>
				<Col>
					<DocumentTitle>
						<h1 className='mb-0'>{ title }</h1>
					</DocumentTitle>
				</Col>
				<Col className='text-right'>
					{ version && (
						<p className='pt-10 mb-0'>
							<strong>Versión:</strong>{" "}
							{ version }
						</p>
					)}
					<p className='pt-5 mb-5'>
						<strong>Fecha:</strong>{" "}
						{ moment(expireAt).format('DD-MMM-YYYY').toUpperCase() }
					</p>
					{ proposalSku && (
						<p>
							<strong>Propuesta:</strong>{" "}
							{proposalSku}
						</p>
					)}
					{ elaboratedAt && (
						<p>
							<strong>Elaborada el:</strong>{" "}
							{ moment(elaboratedAt).format('DD-MMM-YYYY').toUpperCase() }
						</p>
					)}
				</Col>
			</Row>
			<hr className='mt-0 mb-10' />
		</React.Fragment>
	)
}

const DocumentTitle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
	text-align: center;
	h1 {
		font-size: 17px;
		font-weight: bold;
	}
`