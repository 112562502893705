import React from 'react'
import { message, Divider } from 'antd'

import { updateReviewLogbook } from '../../services'
import { handleError, text } from '../../../../helpers'

export default function ReviewLogbookWellTestsTable({ reviewLogbook, reload }) {

    const handleDeleteWellTest = sku => {
        const wellsTests = reviewLogbook.wells_tests.tests.filter(r => r.sku !== sku)
        updateReviewLogbook(reviewLogbook.id, { wells_tests: { tests: wellsTests } })
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error))
    }

    return (
        <React.Fragment>
            <table className='table all_borders compact_table'>
                <tbody className='text-center'>
                    <tr className='text-center'>
                        <td className='bg-light bold' style={{ verticalAlign: 'middle' }} rowSpan={2}>N° Pozo</td>
                        <td className='bg-light bold' colSpan={2}>Antes de la Prueba</td>
                        <td className='bg-light bold' colSpan={2}>Después de la Prueba</td>
                    </tr>
                    <tr className='text-center'>
                        <td className='bg-light bold'>Nivel Freático (m)</td>
                        <td className='bg-light bold'>Contaminación</td>
                        <td className='bg-light bold'>Nivel Freático (m)</td>
                        <td className='bg-light bold'>Contaminación</td>
                    </tr>
                    { (reviewLogbook.wells_tests.tests && reviewLogbook.wells_tests.tests.length > 0) ? (
                        <React.Fragment>
                            { reviewLogbook.wells_tests.tests.map( wellTest => (
                                <tr key={wellTest.sku}>
                                    <td>
                                        {wellTest.sku}
                                        <Divider type="vertical" />
                                        <span className='text-link' onClick={() => handleDeleteWellTest(wellTest.sku)}>
                                            Eliminar
                                        </span>
                                    </td>
                                    <td>{text(wellTest.before_level)}</td>
                                    <td>{text(wellTest.before_contamination)}</td>
                                    <td>{text(wellTest.after_level)}</td>
                                    <td>{text(wellTest.after_contamination)}</td>
                                </tr>
                            )) }
                        </React.Fragment>
                    ) : (
                        <tr className='text-center'>
                            <td colSpan={5} className='text-muted'>
                                <em>No se han ingresado registros</em>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </React.Fragment>
    )
}