import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from 'antd'
import moment from 'moment'

import ServiceReviewResultModal from '../ServiceReviews/partials/ServiceReviewResultModal'
import EditReviewModal from '../ServiceReviews/partials/EditReviewModal'

const { Item } = Descriptions

export default function ServiceResolutionTab({ service, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const { role } = useSelector(state => state.auth.user)
	const [showReviewResultModal, setShowReviewResultModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)

	const serviceReview = (service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review ) ? service.service_inspector.service_plan.service_review : null

	if(!serviceReview) return null

	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Resultado de Inspección</h6>
			<p className='bold mt-20'>REPORTE DE NO CONFORMIDADES</p>
			<Descriptions column={2} bordered size='small' className='mb-10'>
				<Item label="Se presentaron No conformidades">
					{ isConnected ? (serviceReview.total_nc_tasks > 0 ? 'Si' : 'No') : <small>Requiere Internet</small> }
				</Item>
				<Item label="Se requiere inspección de cierre">
					{ isConnected ? (serviceReview.total_nc_tasks > 0 ? 'Si' : 'No') : <small>Requiere Internet</small> }
				</Item>
			</Descriptions>
			<Descriptions column={2} bordered size='small' className='mb-10'>
				<Item span={2} label="Número de hallazgos No conformes identificados pendientes Inspección Cierre">
					{ isConnected ? serviceReview.total_nc_tasks : <small>Requiere Internet</small> }
				</Item>
				<Item span={2} label="Número de No conformidades identificadas con plazo de atención Resolución 40198 de 2021 - No exigible para esta inspección">
					{ isConnected ? serviceReview.total_ncp_tasks : <small>Requiere Internet</small> }
				</Item>
				{ !service.initial_service && (
					<Item style={{ wordBreak: 'normal' }} span={2} label="Plazo máximo para cierre de No conformidades">
						{serviceReview.nc_deadline_at ? moment(serviceReview.nc_deadline_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin específicar</em>}
					</Item>
				)}
				<Item style={{ wordBreak: 'normal' }} span={2} label="Observaciones del inspector:">
					{serviceReview.observations ? serviceReview.observations : <em>Sin específicar</em>}
				</Item>
			</Descriptions>
			{ service.is_editable && (
				<div className='text-right mb-20'>
					<p className='text-link inline' onClick={() => setShowEditModal(true)}>
						Modificar fecha y observaciones
					</p>
				</div>
			)}
			<p className='bold mt-20'>RESULTADO EN ACTA DE INSPECCIÓN</p>
			<Descriptions bordered size="small">
				<Item label="Resultado en acta de inspección">
					{serviceReview.is_compliant === 1 ? 'Conforme' : serviceReview.is_compliant === 0 ? 'No Conforme' : <em>Sin Registro</em> }
				</Item>
			</Descriptions>
			{ (serviceReview.is_compliant === null || role === 'admin' ) && (
				<div className='text-right mt-10 mb-20'>
					<p className='text-link inline' onClick={() => setShowReviewResultModal(true)}>
						Modificar resultado de inspección
					</p>
				</div>
			)}
			{ showReviewResultModal && (
				<ServiceReviewResultModal 
					visible
					onClose={() => setShowReviewResultModal(false)}
					serviceId={service.id}
					serviceReview={serviceReview}
					reload={reload}
				/>
			)}
			{ showEditModal && (
				<EditReviewModal 
					visible
					onClose={() => setShowEditModal(false)}
					service={service}
					serviceReview={serviceReview}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}