import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServiceReviewDocument from './partials/ServiceReviewDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServiceReview } from './services'
import { handleError } from '../../helpers'

export default function ServiceReviewPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [serviceReview, setServiceReview] = useState(null)

	useEffect(() => {
		!serviceReview && isConnected && publicGetServiceReview(id, {
			include: 'servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,islandSpecs,reviewEquipments,servicePlan.serviceInspector.service.proposal.inquiry.employee,tankSpecs,servicePlan.serviceInspector.service.initialService.serviceInspector.servicePlan.serviceReview,servicePlan.serviceInspector.service.serviceInspector.user,formatVersion,reviewLogbooks'
		})
			.then(res => setServiceReview(res.data.data))
			.catch(error => handleError(error))
	}, [serviceReview, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!serviceReview) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServiceReviewDocument serviceReview={serviceReview} />
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`