import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import moment from 'moment'

export default function ReviewLogbookTightnessTestsForm({ register, errors, watch, setValue, specType }) {
    const initialHour = watch('tightness_tests.test1_time')

    useEffect(() => {
        setValue('tightness_tests.test2_time', moment(`2000-01-01 ${initialHour}`).add(10,'minutes').format('HH:mm'))
        setValue('tightness_tests.test3_time', moment(`2000-01-01 ${initialHour}`).add(20,'minutes').format('HH:mm'))
        setValue('tightness_tests.test4_time', moment(`2000-01-01 ${initialHour}`).add(30,'minutes').format('HH:mm'))
        setValue('tightness_tests.test5_time', moment(`2000-01-01 ${initialHour}`).add(40,'minutes').format('HH:mm'))
        setValue('tightness_tests.test6_time', moment(`2000-01-01 ${initialHour}`).add(50,'minutes').format('HH:mm'))
        setValue('tightness_tests.test7_time', moment(`2000-01-01 ${initialHour}`).add(60,'minutes').format('HH:mm'))
    }, [ initialHour, setValue ])

    return (
        <React.Fragment>
            { specType === 'tank' ? (
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Nivel combustible (cm) Inicial</Form.Label>
                        <Form.Control 
                            {...register('tightness_tests.initial_level', {})}
                            placeholder='Escriba aquí...'
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Nivel combustible (cm) Final</Form.Label>
                        <Form.Control 
                            {...register('tightness_tests.final_level', {})}
                            placeholder='Escriba aquí...'
                        />
                    </Form.Group>
                </Row>
            ) : (
                <Form.Group>
                    <Form.Label>¿El Cliente desactivo la bomba sumergible?</Form.Label>
                    <Form.Control
                        as="select"
                        {...register('tightness_tests.bomb_inanctive_by_client', {})}
                    >
                        <option value="">:: Selecciona una opción ::</option>
                        <option>Sí</option>
                        <option>No</option>
                    </Form.Control>
                    <Form.Control 
                        as="textarea"
                        {...register('tightness_tests.bomb_inanctive_by_client_obs', {})}
                        placeholder='Escriba observaciones si tiene'
                        style={{ height: '70px' }}
                    />
                </Form.Group>
            )}
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #1</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test1_time', {})}
                        type="time"
                    />
                    <Form.Text className='text-warning'>Al modificar esta hora se modificara todas las demás.</Form.Text>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test1_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #2</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test2_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test2_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #3</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test3_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test3_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #4</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test4_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test4_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #5</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test5_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test5_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #6</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test6_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test6_presion', {})}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Col xs={2}>
                    <p className='bold mt-20 mb-5'>Prueba #7</p>
                </Col>
                <Form.Group as={Col}>
                    <Form.Label>Hora</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test7_time', {})}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Presión (PSI)</Form.Label>
                    <Form.Control 
                        {...register('tightness_tests.test7_presion', {})}
                    />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}