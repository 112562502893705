import React from 'react'
import { Form } from 'react-bootstrap'

export default function ReviewLogbookRequirementsSurveyForm({ register, errors, watch, specType }) {
    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>{specType === 'tank' ? '4.1 El tanque inspeccionado alcanza la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198' : '4.1 La tubería inspeccionada alcanza la presión mínima recomendada por el fabricante o el rango entre 42.671 – 60 psi'}</Form.Label>
                <Form.Control
                    as="select"
                    {...register('requirements_survey.item_0410', {})}
                >
                    <option value="">:: Selecciona una opción ::</option>
                    <option>Si Cumple</option>
                    <option>No Cumple</option>
                </Form.Control>
                <Form.Control 
                    as="textarea"
                    {...register('requirements_survey.item_0410_obs', {})}
                    placeholder='Escriba las observaciones'
                    style={{ height: '70px' }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{specType === 'tank' ? '4.2 El tanque inspeccionado mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198 de 2021' : '4.2 La tubería inspeccionada mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante o el rango entre 42.671 – 60 psi'}</Form.Label>
                <Form.Control
                    as="select"
                    {...register('requirements_survey.item_0420', {})}
                >
                    <option value="">:: Selecciona una opción ::</option>
                    <option>Si Cumple</option>
                    <option>No Cumple</option>
                </Form.Control>
                <Form.Control 
                    as="textarea"
                    {...register('requirements_survey.item_0420_obs', {})}
                    placeholder='Escriba las observaciones'
                    style={{ height: '70px' }}
                />
            </Form.Group>
        </React.Fragment>
    )
}