import React from 'react'
import styled from 'styled-components'

import DocumentHeader from '../../../../components/DocumentHeader'
import moment from 'moment'

export default function INQUIRY_TEMPLATE_PRU01({ inquiry }) {

	const branch = inquiry.branch_spec.branch
	const company = branch.company

	return (
		<Container>
			<DocumentHeader 
				title="SOLICITUD DE SERVICIO"
				version={inquiry.format_version.sku}
				expireAt={inquiry.format_version.expire_at}
			/>
			<SectionTitle center>VERACIDAD Y SUFICIENCIA DE LA INFORMACIÓN</SectionTitle>
			<SectionContent>Esta solicitud debe diligenciarse con información veraz, declarando la totalidad de la información que se solicita, para analizar la competencia y alcance de DEWAR. Los datos suministrados en este formato serán verificados durante el proceso y corresponderán a los datos consignados en los documentos resultantes del mismo. La información aquí consignada será tratada bajo cumplimiento del principio de confidencialidad.</SectionContent>
			
			<SectionTitle center>CONDICIONES CONTRACTUALES</SectionTitle>
			<SectionContent>Con la información suministrada en este formulario se elaborará la propuesta comercial donde se especificarán las condiciones contractuales del servicio de inspección y los requerimientos y documentos que la instalación debe aportar para la prestación del servicio.</SectionContent>

			<SectionTitle center>DILIGENCIAR TODA LA INFORMACIÓN - Este formulario debe enviarse con el RUT al correo electrónico: comercial@dewar.com.co para cualquier orientación puede comunicarse al CELULAR 3163702077</SectionTitle>

			<SectionTitle style={{ marginTop: '10px' }}>1. INSTALACIÓN DONDE SE PRESTA EL SERVICIO</SectionTitle>
			<div className="p-0">
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="100px">Razón Social:</td>
							<td>{company.name}</td>
							<td width='80px'>NIT</td>
							<td>{company.complete_document}</td>
						</tr>
						<tr>
							<td width="140px">Representante Legal:</td>
							<td colSpan={3}>{company.legal_owner_name}</td>
						</tr>
						<tr>
							<td width="160px">Nombre de la instalación:</td>
							<td colSpan={3}>{branch.name}</td>
						</tr>
					</tbody>
				</table>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="80px">Dirección:</td>
							<td>{branch.address_line}</td>
							<td width="100px">Departamento:</td>
							<td>{branch.address_state}</td>
							<td width="80px">Municipio:</td>
							<td>{branch.address_city}</td>
						</tr>
						<tr>
							<td width="120px">Correo electrónico:</td>
							<td>{branch.email}</td>
							<td width="100px">Teléfono:</td>
							<td>{branch.phone}</td>
							<td width="80px">Celular:</td>
							<td>{branch.mobile}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<SectionTitle>2. DATOS DE LA PERSONA RESPONSABLE DE ATENDER EL SERVICIO</SectionTitle>
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="130px">Nombre y Apellido:</td>
							<td>{inquiry.employee.name}</td>
							<td width="100px">Cargo:</td>
							<td>{inquiry.employee.position}</td>
						</tr>
						<tr>
							<td width="120px">Correo electrónico:</td>
							<td>{inquiry.employee.email}</td>
							<td width="100px">Celular:</td>
							<td>{inquiry.employee.mobile}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<SectionTitle>3. SERVICIO QUE SOLICITA</SectionTitle>
			<SectionContent className="text-center bold">{inquiry.test_details.service_name}</SectionContent>

			<SectionTitle style={{ marginTop: '10px' }}>4. AUTORIZACIÓN TRATAMIENTO DE DATOS PERSONALES:</SectionTitle>						
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td className='text-center bg-light'>Otorgo mi consentimiento a DEWAR SAS para tratar mi información personal, de acuerdo con la política de tratamiento de datos personales, en concordancia con la Ley 1581 de 2012, y el Decreto reglamentario 1377 de 2013</td>
							<td className='bg-light text-center bold pt-20' width="180px">ACEPTO</td>
						</tr>
					</tbody>
				</table>
			</div>
			<SectionTitle>INFORMACIÓN ADICIONAL ESPECIFICA ÚTIL PARA DEWAR SOBRE LA PRESTACIÓN DEL SERVICIO</SectionTitle>
			<SectionContent style={{ height: '50px' }} >{inquiry.observations}</SectionContent>
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="235px" className='bold bg-light'>Nombre de quien envía la solicitud:</td>
							<td>{inquiry.sent_by}</td>
							<td width="215px" className='bold bg-light'>Fecha de envío de esta solicitud:</td>
							<td>{inquiry.sent_at}</td>
						</tr>
						<tr>
							<td colSpan={4} className='text-center' style={{ backgroundColor: '#c0c0c0' }}>De ser requerido información adicional será solicitada al cliente, una vez se revise la presente solicitud de servicio.</td>
						</tr>
					</tbody>
				</table>
			</div>

			<SectionTitle style={{ marginTop: '10px' }}>5. ESPECIFICACIONES PRUEBAS DE HERMETICIDAD TANQUES HORIZONTALES Y TUBERIAS</SectionTitle>
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td colSpan={4} className='bg-light bold text-center px-10'>* Si la instalación tiene un tanque bicompartido este tanque requiere una prueba por compartimiento.<br/> 1 tanque bicompartido = 2 tanques</td>
						</tr>
						<tr>
							<td width="150px" className='bold bg-light'>Número de tanques*:</td>
							<td>{inquiry.test_details.total_tanks}</td>
							<td width="210px" className='bold bg-light'>Altura aproximada del desfogue:</td>
							<td>{inquiry.test_details.aprox_altitude}</td>
						</tr>
						<tr>
							<td colSpan={2} className='bg-light bold' width="100px">¿Tiene válvula para independizar el desfogue del tanque?</td>
							<td colSpan={2}>{inquiry.test_details.valve_present}</td>
						</tr>
						<tr>
							<td colSpan={2} width="215px" className='bold bg-light'>Fecha de las últimas pruebas de hermeticidad (día/mes/año):</td>
							<td colSpan={2}>{moment(inquiry.test_details.last_test_at).format('DD/MM/YYYY')}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Container>
	)
}

const Container = styled.div`
	font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
`

const SectionTitle = styled.div`
	background-color: #fdeada;
	font-weight: bold;
	padding: 2px 6px;
	border: 1px solid black;
	margin: 0px;
	color: black;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const SectionContent = styled.div`
	padding: 5px;
	font-size: 12px;
	border: 1px solid black;
	margin: 0px;
	color: black;
`