import React from 'react'
import { Result } from 'antd'

export default function DocumentTemplate404() {
    return (
        <React.Fragment>
            <Result 
                status="404"
                title="El formato no ha sido encontrado"
                subTitle="Favor informar al correo de soporte técnico: soporte@smart4.com.co"
            />
        </React.Fragment>
    )
}