import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

import { storeDecisionNotice } from '../services'
import { handleError } from '../../../helpers'

export default function NewDecisionNoticeModal({ visible, onClose, serviceVeredict, businessScope, noCompliant, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeDecisionNotice({ service_veredict_id: serviceVeredict.id, ...values })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Emitir Comunicado Decisión Proceso de Inspección de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="decisionNotice"
									setValue={setValue}
									businessScope={businessScope}
								/>
								{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control {...register('expire_at')} disabled />
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Fecha del Comunicado <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="date"
								{...register('published_at', { required: true })}
							/>
							{ errors.published_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						{ noCompliant && businessScope === 'inspeccion' && (
							<Form.Group>
								<Form.Label>Motivo para No Otorgar Personalizado <small>(opcional)</small></Form.Label>
								<Form.Control 
									as="textarea"
									{...register('custom_response')}
									style={{ height: '70px' }}
								/>
							</Form.Group>
						)}
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Generar Comunicado
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}