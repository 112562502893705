import React from 'react'
import styled from 'styled-components'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'
import { text } from '../../../../helpers'

export default function REVIEW_LOGBOOK_TEMPLATE_PRU01({ reviewLogbook }) {

    const serviceInspector = reviewLogbook.service_review.service_plan.service_inspector
    const service = serviceInspector.service
    const inquiry = service.proposal.inquiry
    const branch = inquiry.branch_spec.branch

    return (
        <Container>
            <DocumentHeader
				title="BITACORA DE INSPECCIÓN TANQUES"
				version={reviewLogbook.format_version.sku}
				expireAt={reviewLogbook.format_version.expire_at}
				proposalSku={serviceInspector.service.proposal.sku}
				logoSrc={logo}
			/>
            <table className='table all_borders mt-3'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='small text-center'>El presente formato es entregado para desarrollo exclusivo de la labor o servicio que han sido contratados. Su uso no autorizado constituye infracción de la confidencialidad y al secreto empresarial. No podrá ser copiado o rediseñado sin autorización expresa del titular DEWAR SAS</td>
                    </tr>
                    <tr>
                        <td className='title' width="200px">Fecha:</td>
                        <td>{reviewLogbook.register_at}</td>
                        <td className='title' width="200px">Inspector asignado:</td>
                        <td>{serviceInspector.user_name}</td>
                    </tr>
                    <tr>
                        <td className='title'>Nombre de Instalación:</td>
                        <td colSpan={3}>{branch.name}</td>
                    </tr>
                    <tr>
                        <td className='title'>Criterio de la Inspección:</td>
                        <td colSpan={3}>{service.criteria}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={8} className='title'>MODULO 1. IDENTIFICACIÓN DE LOS TANQUES</td>
                    </tr>
                    <tr>
                        <td className='title' width="110px">Nº Tanque</td>
                        <td width="80px">{reviewLogbook.spec_number}</td>
                        <td className='title' width="110px">Identificación del tanque</td>
                        <td>{text(reviewLogbook.spec_details.identificacion_tanque)}</td>
                        <td className='title' width="110px">Capacidad (gal)</td>
                        <td>{text(reviewLogbook.spec_details.capacidad)}</td>
                        <td className='title' width="110px">Producto</td>
                        <td width="80px">{reviewLogbook.product_name} {reviewLogbook.product_other_name}</td>
                    </tr>
                    <tr>
                        <td className='title'>Tipo</td>
                        <td>{reviewLogbook.spec_category_slug}</td>
                        <td className='title'>Material</td>
                        <td>{text(reviewLogbook.spec_details.material)}</td>
                        <td className='title'>Nivel de llenado (gal)</td>
                        <td>{text(reviewLogbook.spec_details.nivel_llenado)}</td>
                        <td className='title'>Diámetro de tanque (m)</td>
                        <td>{text(reviewLogbook.spec_details.diametro_tanque)}</td>
                    </tr>
                    <tr>
                        <td className='title'>Bicompartido?</td>
                        <td>{text(reviewLogbook.spec_details.bicompartido)}</td>
                        <td className='title'>Doble contención</td>
                        <td>{text(reviewLogbook.spec_details.doble_contencion)}</td>
                        <td colSpan={2} className='title'>Verifica si hay olor HC</td>
                        <td colSpan={2}>{text(reviewLogbook.spec_details.olor_hc)}</td>
                    </tr>
                    { reviewLogbook.spec_details.doble_contencion === 'Sí' && (
                        <tr>
                            <td className='title'>Espacio Anular</td>
                            <td>{text(reviewLogbook.spec_details.espacio_anular)}</td>
                            <td colSpan={2} className='title'>Nivel de Salmuera Inicial (cm)</td>
                            <td>{text(reviewLogbook.spec_details.salmuera_inicial)}</td>
                            <td colSpan={2} className='title'>Nivel de Salmuera Final (cm)</td>
                            <td>{text(reviewLogbook.spec_details.salmuera_final)}</td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={8} className='subtitle'>Información adicional</td>
                    </tr>
                    <tr>
                        <td className='subtitle'>No. De bocas</td>
                        <td>{text(reviewLogbook.spec_details.numero_bocas)}</td>
                        <td colSpan={2} className='subtitle'>Diámetro de bocas (pulg)</td>
                        <td>{text(reviewLogbook.spec_details.diametro_bocas)}</td>
                        <td colSpan={2} className='subtitle'>Diámetro de manhole (pulg)</td>
                        <td>{text(reviewLogbook.spec_details.diametro_manhole)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody className='text-center'>
                    <tr>
                        <td colSpan={4} className='title'>MODULO 2. PREGUNTAS INSTALACIÓN DE EQUIPOS</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='title'>1. Aspectos a  verificar</td>
                        <td className='title'>Respuesta</td>
                        <td className='title'>Observaciones (Opcional)</td>
                    </tr>
                    <tr>
                        <td width="30px" className='bold'>1.1</td>
                        <td>¿Ha tenido durante el último inventario algún registro de pérdida de volumen?</td>
                        <td width="100px">{text(reviewLogbook.survey_answers.perdida_volumen)}</td>
                        <td width="180px">{text(reviewLogbook.survey_answers.perdida_volumen_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>1.2</td>
                        <td>¿Se han realizado modificaciones al cuerpo del tanque o a la instalación de combustible?</td>
                        <td>{text(reviewLogbook.survey_answers.modificaciones_cuerpo)}</td>
                        <td>{text(reviewLogbook.survey_answers.modificaciones_cuerpo_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>1.3</td>
                        <td>¿Ha presentado algún ingreso constante de agua?</td>
                        <td>{text(reviewLogbook.survey_answers.ingreso_agua)}</td>
                        <td>{text(reviewLogbook.survey_answers.ingreso_agua_obs)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody className='text-center'>
                    <tr>
                        <td colSpan={3} className='title'>2. Instalaciones Hidraúlicas</td>
                        <td colSpan={2} className='title'></td>
                    </tr>
                    <tr>
                        <td rowSpan={3} width="100px" className='subtitle'>Tubería de desfogue</td>
                        <td width="30px" className='bold'>2.1</td>
                        <td>¿Existe TE en tubería de desfogue?</td>
                        <td width="100px">{text(reviewLogbook.survey_answers.tuberia_desfogue)}</td>
                        <td width="180px">{text(reviewLogbook.survey_answers.tuberia_desfogue_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>2.2</td>
                        <td>¿Se prueba tubería de desfogue?</td>
                        <td>{text(reviewLogbook.survey_answers.prueba_tuberia_desfoge)}</td>
                        <td>{text(reviewLogbook.survey_answers.prueba_tuberia_desfoge_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>2.3</td>
                        <td>¿Se tapona la TE?</td>
                        <td>{text(reviewLogbook.survey_answers.tapona_te)}</td>
                        <td>{text(reviewLogbook.survey_answers.tapona_te_obs)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={3} className='subtitle'>Tubería de llenado</td>
                        <td className='bold'>2.4</td>
                        <td>Tipo de llenado</td>
                        <td colSpan={2}>{text(reviewLogbook.survey_answers.tipo_llenado)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>2.5</td>
                        <td>¿Tiene Spill container?</td>
                        <td>{text(reviewLogbook.survey_answers.spill_container)}</td>
                        <td>{text(reviewLogbook.survey_answers.spill_container_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>2.6</td>
                        <td>¿Se Prueba tubería de llenado?</td>
                        <td>{text(reviewLogbook.survey_answers.prueba_llenado)}</td>
                        <td>{text(reviewLogbook.survey_answers.prueba_llenado_obs)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2} className='subtitle'>Tubería de medida</td>
                        <td className='bold'>2.7</td>
                        <td>¿ Tiene sonda de medición?</td>
                        <td>{text(reviewLogbook.survey_answers.sonda_medicion)}</td>
                        <td>{text(reviewLogbook.survey_answers.sonda_medicion_obs)}</td>
                    </tr>
                    <tr>
                        <td className='bold'>2.8</td>
                        <td>Se instala equipo de prueba:</td>
                        <td colSpan={2}>{text(reviewLogbook.survey_answers.equipo_prueba)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='bold'>2.12</td>
                        <td>Se verifica cierre de válvula de bomba sumergible</td>
                        <td>{text(reviewLogbook.survey_answers.cierre_valvula)}</td>
                        <td>{text(reviewLogbook.survey_answers.cierre_valvula_obs)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className='title'>3. Instalaciones Eléctricas</td>
                        <td colSpan={2} className='title'></td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='bold'>3.1</td>
                        <td>¿El Cliente desactivo la bomba sumergible?</td>
                        <td>{text(reviewLogbook.survey_answers.descativo_bomba)}</td>
                        <td>{text(reviewLogbook.survey_answers.descativo_bomba_obs)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='title'>MODULO 3. EJECUCIÓN PRUEBAS TANQUES</td>
                    </tr>
                    { reviewLogbook.spec_category_slug === 'superficial' && (
                        <React.Fragment>
                            <tr>
                                <td colSpan={2} className='subtitle'>3.1 Información Pozos</td>
                                <td className='subtitle'>Número de pozos</td>
                                <td width="100px">{reviewLogbook.wells_tests.tests && reviewLogbook.wells_tests.tests.length + 1}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className='p-5 pb-0'>
                                    <table className='table all_borders'>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} className='bold text-center'>ANTES DE LA PRUEBA</td>
                                            </tr>
                                            <tr>
                                                <td className='subtitle'>N° Pozo</td>
                                                <td className='subtitle'>Nivel Freático (m)</td>
                                                <td className='subtitle'>Contaminación</td>
                                            </tr>
                                            { (reviewLogbook.wells_tests.tests && reviewLogbook.wells_tests.tests.length > 0) ? (
                                                <React.Fragment>
                                                    { reviewLogbook.wells_tests.tests.map(test => (
                                                        <tr key={`B${test.sku}`} className='text-center'>
                                                            <td>{test.sku}</td>
                                                            <td>{test.before_level ? test.before_level : 'Sin registro'}</td>
                                                            <td>{test.before_contamination ? test.before_contamination : 'Sin registro'}</td>
                                                        </tr>
                                                    )) }
                                                </React.Fragment>
                                            ) : (
                                                <tr className="text-center">
                                                    <td colSpan={3}>Sin registros</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                                <td colSpan={2} className='p-5 pb-0'>
                                    <table className='table all_borders'>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} className='bold text-center'>DESPÚES DE LA PRUEBA</td>
                                            </tr>
                                            <tr>
                                                <td className='subtitle'>N° Pozo</td>
                                                <td className='subtitle'>Nivel Freático (m)</td>
                                                <td className='subtitle'>Contaminación</td>
                                            </tr>
                                            { (reviewLogbook.wells_tests.tests && reviewLogbook.wells_tests.tests.length > 0) ? (
                                                <React.Fragment>
                                                    { reviewLogbook.wells_tests.tests.map(test => (
                                                        <tr key={`A${test.sku}`} className='text-center'>
                                                            <td>{test.sku}</td>
                                                            <td>{test.after_level ? test.after_level : 'Sin registro'}</td>
                                                            <td>{test.after_contamination ? test.after_contamination : 'Sin registro'}</td>
                                                        </tr>
                                                    )) }
                                                </React.Fragment>
                                            ) : (
                                                <tr className="text-center">
                                                    <td colSpan={3}>Sin registros</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={6} className='title'>3.2 PRUEBA DE VERIFICACION DE LA HERMETICIDAD</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='subtitle'>Nivel combustible (cm) Inicial</td>
                        <td width="140px">{text(reviewLogbook.tightness_tests.initial_level)}</td>
                        <td colSpan={2} className='subtitle'>Nivel combustible (cm) Final</td>
                        <td width="140px">{text(reviewLogbook.tightness_tests.final_level)}</td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='title'>REGISTROS  DE VERIFICACION DE LA HERMETICIDAD TANQUE</td>
                    </tr>
                    <tr className='text-center'>
                        <td width="120px" className='bold'>Hora</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test1_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test2_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test3_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test4_time)}</td>
                        <td width="100px">{text(reviewLogbook.tightness_tests.test5_time)}</td>
                    </tr>
                    <tr className="text-center">
                        <td width="120px" className='bold'>Presión (PSI)</td>
                        <td>{text(reviewLogbook.tightness_tests.test1_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test2_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test3_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test4_presion)}</td>
                        <td>{text(reviewLogbook.tightness_tests.test5_presion)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders mt-20'>
                <tbody>
                    <tr>
                        <td colSpan={4} className='title'>MODULO 4. LISTA DE VERIFICACIÓN TANQUES</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='title'>Requisitos</td>
                        <td className='title'>Cumplimiento</td>
                        <td className='title'>Observaciones (Opcional)</td>
                    </tr>
                    <tr>
                        <td width="30px" className='bold'>4.1</td>
                        <td>El tanque inspeccionado alcanza la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198</td>
                        <td width="100px">{text(reviewLogbook.requirements_survey.item_0410)}</td>
                        <td width="180px">{text(reviewLogbook.requirements_survey.item_0410_obs)}</td>
                    </tr>
                    <tr>
                        <td width="30px" className='bold'>4.2</td>
                        <td>El tanque inspeccionado mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198 de 2021</td>
                        <td width="100px">{text(reviewLogbook.requirements_survey.item_0420)}</td>
                        <td width="180px">{text(reviewLogbook.requirements_survey.item_0420_obs)}</td>
                    </tr>
                </tbody>
            </table>
        </Container>
    )
}

const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 4px 3px;
        vertical-align: middle;
	}
    & table.table {
        margin-bottom: 3px;
    }
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
    & table tr td.title {
        background-color: #f6c6ac;
        font-weight: bold;
        text-align: center;
    }
    & table tr td.subtitle {
        background-color: #fae2d5;
        font-weight: bold;
        text-align: center;
    }
`