import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty } from 'antd'
import moment from 'moment'

import NewServiceInspectorModal from './partials/NewServiceInspectorModal'
import AcceptServiceModal from './partials/AcceptServiceModal'
import RejectServiceModal from './partials/RejectServiceModal'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

import { currency } from '../../helpers'
import EditServiceInspectorModal from './partials/EditServiceInspectorModal'

const { Item } = Descriptions

export default function ServiceInspectorTab({ service, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showAcceptModal, setShowAcceptModal] = useState(false)
	const [showRejectModal, setShowRejectModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const serviceInspector = service.service_inspector

	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Asignación de Inspección</h6>
			{ serviceInspector ? (
				<React.Fragment>
					<Descriptions bordered className='pt-10 mb-15' size='small' column={2}>
						<Item label="Versión">
							{serviceInspector.format_version.sku}
						</Item>
						<Item label="Fecha Vigencia">
							{moment(serviceInspector.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Inspector Asignado">
							{serviceInspector.user.name}
						</Item>
						<Item label="Fecha envío asignación">
							{moment(serviceInspector.assigned_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Código Proceso">
							{service.proposal.sku}
						</Item>
						<Item label="Valor Servicio Inspección">
							$ {currency(serviceInspector.service_value)}
						</Item>
						<Item label="Total Gastos de Viaje">
							$ {currency(serviceInspector.trip_expenses)}
						</Item>
						<Item label="Fecha de Aceptado">
							{serviceInspector.accepted_at ? moment(serviceInspector.accepted_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin aceptación</em>}
						</Item>
						<Item label="Observaciones Específicas" span={2}>
							{serviceInspector.observations}
						</Item>
						{ service.business_scope === 'pruebas' && (
							<React.Fragment>
								<Item label="Número de tanques">{serviceInspector.test_details.total_tanks}</Item>
								<Item label="Altura aproximada del desfogue">{serviceInspector.test_details.aprox_altitude}</Item>
								<Item label="Válvula para independizar desfogue" span={2}>{serviceInspector.test_details.valve_present}</Item>
							</React.Fragment>
						)}
					</Descriptions>
					{ service.business_scope === 'pruebas' && !serviceInspector.accepted_at && (
						<div className='text-right mt-10 mb-20'>
							<p className='text-link' onClick={() => setShowEditModal(true)}>
								Modificar Asignación de Inspección
							</p>
						</div>
					)}
					<Link to={`/service_inspectors/preview/${serviceInspector.id}`}>
						<Button color="primary" outline>Formato de Asignación de Inspección</Button>
					</Link>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(serviceInspector.id)}>
						Correos Enviados
					</Button>
					<div className='mt-10' />
					{ !serviceInspector.accepted_at && (
						<React.Fragment>
							<Button color="primary" outline onClick={() => setShowAcceptModal(true)}>
								Aceptar Asignación
							</Button>
							<Divider type="vertical" />
							<Button color="primary" outline onClick={() => setShowRejectModal(true)}>
								Rechazar Asignación
							</Button>
						</React.Fragment>
					)}
				</React.Fragment>
			) : (
				<Empty description="No se ha asignado la inspección aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Asignar Inspector
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewServiceInspectorModal 
					visible
					onClose={() => setShowNewModal(false)}
					service={service}
					reload={reload}
				/>
			)}
			{ showAcceptModal && (
				<AcceptServiceModal 
					visible
					onClose={() => setShowAcceptModal(false)}
					serviceInspector={serviceInspector}
					reload={reload}
				/>
			)}
			{ showRejectModal && (
				<RejectServiceModal 
					visible
					onClose={() => setShowRejectModal(false)}
					serviceInspector={serviceInspector}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_inspector"
					modelId={emailLogsModal}
				/>
			)}
			{ showEditModal && (
				<EditServiceInspectorModal 
					visible
					onClose={() => setShowEditModal(false)}
					reload={reload}
					service={service}
				/>
			)}
		</React.Fragment>
	)
}