import React from 'react'

import INQUIRY_TEMPLATE_INS01 from './templates/INQUIRY_TEMPLATE_INS01';
import INQUIRY_TEMPLATE_PRU01 from './templates/INQUIRY_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function InquiryDocument({ inquiry }) {
	
	switch (inquiry.format_version.format_template) {
		case 'INQUIRY_TEMPLATE_INS01':
			return <INQUIRY_TEMPLATE_INS01 inquiry={inquiry} />

		case 'INQUIRY_TEMPLATE_PRU01':
			return <INQUIRY_TEMPLATE_PRU01 inquiry={inquiry} />
	
		default:
			return <DocumentTemplate404 />
	}

}