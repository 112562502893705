import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm } from 'antd'

import ReviewAttachmentModal from './ReviewAttachmentModal'

import { updateReviewLogbook } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewLogbookAttachments({ reviewLogbook, onClose, reload }) {
    const [newAttachmentModal, setNewAttachmentModal] = useState(null)

    const handleUpdateReviewLogbook = values => {
        updateReviewLogbook(reviewLogbook.id, values)
            .then(res => {
                message.success(res.data.message)
                onClose()
            })
            .catch(error => handleError(error))
    }

    const attachments = [
        {
            attribute: 'results_graphic_uri',
            title: 'GRÁFICO DE RESULTADOS',
            emptyLabel: 'No se tiene gráfico de resultados',
            ctaText: 'Subir Gráfico de Resultados'
        },
        {
            attribute: 'results_data_uri',
            title: 'ARCHIVO DATOS DE RESULTADOS',
            emptyLabel: 'No se tiene archivo de resultados',
            ctaText: 'Subir Archivo de Resultados'
        },
        {
            attribute: 'results_scheme_uri',
            title: 'ESQUEMA DE DISTRIBUCIÓN DE EQUIPOS',
            emptyLabel: 'No se tiene esquema de distribución',
            ctaText: 'Subir Esquema de Distribución'
        }
    ]

    return (
        <React.Fragment>
            { attachments.map(attachment => (
                <React.Fragment key={attachment.attribute}>
                    <p className='bold'>{attachment.title}</p>
                    { reviewLogbook[attachment.attribute] ? (
                        <div className='text-center bg-light py-10 mb-10'>
                            <a href={reviewLogbook[attachment.attribute]} target='_blank' rel="noreferrer">Descargar Archivo Actual</a>
                        </div>
                    ) : (
                        <Empty description={attachment.emptyLabel} imageStyle={{ height: '50px' }} />
                    )}
                    <div className='text-right'>
                        <span className='text-link mt-20' onClick={() => setNewAttachmentModal(attachment.attribute)}>
                            {attachment.ctaText}
                        </span>
                        { reviewLogbook[attachment.attribute] && (
                            <React.Fragment>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="Esta acción no se puede revertir"
                                    okText="Eliminar"
                                    cancelText="Cancelar"
                                    onConfirm={() => handleUpdateReviewLogbook({ [attachment.attribute]: '' })}
                                >
                                    <span className='text-right text-link mt-20'>
                                        Eliminar Adjunto
                                    </span>
                                </Popconfirm>
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )) }

            { newAttachmentModal && (
                <ReviewAttachmentModal 
                    visible
                    onClose={() => setNewAttachmentModal(null)}
                    reload={reload}
                    reviewLogbookId={reviewLogbook.id}
                    attribute={newAttachmentModal}
                />
            )}
        </React.Fragment>
    )
}