import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { message } from 'antd'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { updateServiceReview } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewResultsModal({ visible, onClose, serviceReview, serviceId, businessScope, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit } = useForm({})
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(isConnected){
			updateServiceReview(serviceReview.id, values)
				.then(res => {
					message.success(res.data.message)
					onClose()
					reload()
				})
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview(values, serviceId))
			dispatch(queueRequest({url: `/service_reviews/${serviceReview.id}`, data: values}))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultados de Inspección</ModalHeader>
				<ModalBody>
					{ serviceReview ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							{ businessScope === 'inspeccion' && (
								<Row>
									<Form.Group as={Col}>
										<Form.Label>Fecha de Inicio:</Form.Label>
										<Form.Control 
											type="date"
											{...register('review_specs.start_at', { required: true })}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Fecha Finalización:</Form.Label>
										<Form.Control 
											type="date"
											{...register('review_specs.end_at', { required: true })}
										/>
									</Form.Group>
								</Row>
							)}
							<Form.Group>
								<Form.Label>Resultados con Cumplimiento <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.results_if_compliant', { required: true })}
									defaultValue={serviceReview.review_specs.results_if_compliant}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Resultados sin Cumplimiento <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.results_if_not_compliant', { required: true })}
									defaultValue={serviceReview.review_specs.results_if_not_compliant}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Declaración Finalización del Servicio <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.final_declaration', { required: true })}
									defaultValue={serviceReview.review_specs.final_declaration}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Observaciones del Inspector <em>(Opcional)</em></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('observations', { required: true })}
									defaultValue={serviceReview.observations}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Button color="primary" type="submit">Actualizar Información</Button>
						</Form>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}