import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import ImpartialityNoticeDocument from './partials/ImpartialityNoticeDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getServiceReview } from '../ServiceReviews/services'
import { handleError } from '../../helpers'

export default function ImpartialityNoticePreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const serviceReviewId = props.match.params.serviceReviewId
	const [serviceReview, setServiceReview] = useState(null)

	useEffect(() => {
		!serviceReview && isConnected && getServiceReview(serviceReviewId, {
			include: 'servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,servicePlan.serviceInspector.user,servicePlan.serviceInspector.service.proposal.inquiry.employee,impartialityNotice.formatVersion'
		})
			.then(res => setServiceReview(res.data.data))
			.catch(error => handleError(error))
	}, [ serviceReviewId, serviceReview, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!serviceReview) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ImpartialityNoticeDocument 
					serviceReview={serviceReview} 
				/>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`