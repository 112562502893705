import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServiceNotificationDocument from './partials/ServiceNotificationDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServiceNotification } from './services'
import { handleError } from '../../helpers'

export default function ServiceNotificationPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [serviceNotification, setServiceNotification] = useState(null)

	useEffect(() => {
		!serviceNotification && isConnected && publicGetServiceNotification(id, {
			include: 'serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceInspector.user,formatVersion'
		})
			.then(res => setServiceNotification(res.data.data))
			.catch(error => handleError(error))
	}, [serviceNotification, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!serviceNotification) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServiceNotificationDocument 
					serviceNotification={serviceNotification} 
				/>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`