import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import DepartmentAndCityForm from '../../../components/DepartmentAndCityForm'

export default function CompanyForm({ company, register, errors, watch }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Razón Social <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>NIT de la Empresa <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('document', {required:true})} />
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Dígito de Verificación <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('verification_code', {required:true})} type="number" />
					{ errors.verification_code && <Form.Text className="text-warning">Este campo es requerido. Debe ser entre 0 y 9.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Nombre Representante Legal <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('legal_owner_name', {required:true})} />
					{ errors.legal_owner_name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Cédula <small>(opcional)</small></Form.Label>
					<Form.Control {...register('legal_owner_document', {})} />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('email', {required:true})} />
				{ errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico #2 <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email_secundary', {})} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico #3 <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email_terciary', {})} />
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Teléfono Celular <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('mobile', {required:true})} />
					{ errors.mobile && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Teléfono Fijo <small>(opcional)</small></Form.Label>
					<Form.Control {...register('phone', {})} />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Dirección Completa <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('address_line', {required:true})} />
				{ errors.address_line && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<DepartmentAndCityForm
				register={register} 
				errors={errors} 
				watch={watch} 
				current={company}
			/>
			<Form.Group>
				<Form.Label>Agente retenedor de retención en la fuente</Form.Label>
				<Form.Control
					as="select"
					{...register('is_autorretenedor', { required: true })}
				>
					<option value="" disabled>:: Seleccione una opción ::</option>
					<option value="0">No</option>
					<option value="1">Si</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico Facturación <small>(opcional)</small></Form.Label>
				<Form.Control {...register('invoicing_email', {})} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Dirección Fisica Envío Certificado <small>(opcional)</small></Form.Label>
				<Form.Control {...register('certificate_address_line', {})} />
			</Form.Group>
		</React.Fragment>
	)
}