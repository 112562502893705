import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'

export default function ACCEPTANCE_TEMPLATE_INS01({ data, hideHeader = false }) {
	return (
		<Document>
			{ !hideHeader && (
				<DocumentHeader 
					title="CARTA DE ACEPTACIÓN"
					expireAt={moment()}
				/>
			)}
			<p className='mb-5'>Señor</p>
			<p className='mb-5'><strong>Oscar Alberto Domínguez</strong></p>
			<p className='mb-5'>Representante Legal</p>
			<p className='mb-5'><strong>DEWAR S.A.S</strong></p>
			<p className='mb-20'>Carrera 61 # 96 A 23 Oficina 201 Barrio Andes</p>
			<p className='mb-30'>Bogotá – Colombia</p>
			<p>Yo, <strong>{data.inquiry.branch_spec.branch.company.legal_owner_name}</strong> identificado con la cédula de ciudadanía N° <strong>{data.inquiry.branch_spec.branch.company.legal_owner_document}</strong> como Representante Legal de <strong>{data.inquiry.branch_spec.branch.company.name}</strong> NIT <strong>{data.inquiry.branch_spec.branch.company.complete_document}</strong> solicito que se adelante el proceso de inspección para la instalación <strong>{data.inquiry.branch_spec.branch.name}</strong> de acuerdo a la propuesta N° <strong>{data.sku}</strong> presentada por el Organismo de Evaluación de la Conformidad DEWAR SAS.</p>
			<p>Declaro conocer y acoger el documento DN5 “Reglamento de inspección” establecido por DEWAR, así como el alcance del proceso de inspección que se realizaran a nuestras instalaciones.</p>
			{ data.is_ds_construction === 1 && (
				<p>Por otra parte, declaro identificar al constructor de mi instalación, Domínguez Sánchez S.A.S como entidad relacionada con DEWAR, entendiendo que no existe una relación entre dichas que afecte la imparcialidad de la inspección y la autonomía de la decisión de certificación, y respetaré el cumplimiento de los principios de independencia e imparcialidad de DEWAR como Organismo evaluador de la conformidad.</p>
			)}
			<p className='mb-30'>Anexo el certificado de existencia y representación legal de la cámara de comercio con emisión no superior a tres (3) meses y el RUT solicitado por DEWAR para iniciar el proceso de certificación.</p>
			<p className='mb-40'>Atentamente,</p>
			<p className='pt-20'>_______________________</p>
			<p>Firma Representante Legal</p>
			<p className='mb-0'><strong>{data.inquiry.branch_spec.branch.company.legal_owner_name}</strong></p>
			<p>Cédula: <strong>{data.inquiry.branch_spec.branch.company.legal_owner_document}</strong></p>
		</Document>
	)
}

const Document = styled.div`
	max-width: 1250px;
	padding: 15px 40px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
`