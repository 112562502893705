import React from 'react'

import SERVICE_REPORT_TEMPLATE_INS01 from './templates/SERVICE_REPORT_TEMPLATE_INS01'
import SERVICE_REPORT_TEMPLATE_PRU01 from './templates/SERVICE_REPORT_TEMPLATE_PRU01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function ServiceReportDocument({ serviceReport }) {
	
	switch (serviceReport.format_version.format_template) {
		case 'SERVICE_REPORT_TEMPLATE_INS01':
			return <SERVICE_REPORT_TEMPLATE_INS01 serviceReport={serviceReport} />

		case 'SERVICE_REPORT_TEMPLATE_PRU01':
			return <SERVICE_REPORT_TEMPLATE_PRU01 serviceReport={serviceReport} />
	
		default:
			return <DocumentTemplate404 />
	}

}