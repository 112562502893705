import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'

import { text } from '../../../../helpers'

export default function SERVICE_REVIEW_TEMPLATE_PRU01({ data, serviceData = null }) {
    
    const service = serviceData ? serviceData : data.service_plan.service_inspector.service
    const branch = service.proposal.inquiry.branch_spec.branch

    const parseResults = reviewLogbook => {
        if(!reviewLogbook || !reviewLogbook.requirements_survey || !reviewLogbook.requirements_survey.item_0410 || !reviewLogbook.requirements_survey.item_0420) 
            return <em className='small text-muted'>Sin registro</em>

        if(reviewLogbook.requirements_survey.item_0410.includes('No') || reviewLogbook.requirements_survey.item_0420.includes('No'))
            return 'No Cumple'

        return 'Cumple'
    }
    
    const parseObservations = reviewLogbook => {
        if(!reviewLogbook) return ''

        if(!reviewLogbook.requirements_survey.item_0410_obs && !reviewLogbook.requirements_survey.item_0420_obs)
            return <em className='small text-muted'>Sin observación</em>

        return reviewLogbook.requirements_survey.item_0410_obs + ' ' + reviewLogbook.requirements_survey.item_0420_obs
    }

    return (
        <Container>
            <DocumentHeader
				title="ACTA DE INSPECCIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				proposalSku={service.proposal.sku}
				logoSrc={logo}
			/>
            <SectionTitle>1. INFORMACIÓN GENERAL</SectionTitle>
            <table className='table all_borders mb-0'>
                <tbody>
                    <tr>
                        <td className='bg-light bold' width="170px">Fecha de inicio:</td>
                        <td>{ data.service_plan.inspected_at ? moment(data.service_plan.inspected_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin fecha</em> }</td>
                        <td className='bg-light bold' width="170px">N° Asignación inspección</td>
                        <td>{data.service_plan.service_inspector.sku}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Inspector asignado:</td>
                        <td>{service.service_inspector.user.name}</td>
                        <td className='bg-light bold'>Correo electrónico:</td>
                        <td>{text(service.service_inspector.user.email)}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Criterio de la Inspección:</td>
                        <td colSpan={3}>{service.criteria}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>2. INFORMACIÓN INSTALACIÓN</SectionTitle>
            <table className='table all_borders mb-0'>
                <tbody>
                    <tr>
                        <td width="150px" className='bg-light bold'>Razón Social:</td>
                        <td>{branch.company.name}</td>
                        <td width="150px" className='bg-light bold'>NIT</td>
                        <td>{branch.company.complete_document}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Representante Legal:</td>
                        <td colSpan={3}>{branch.company.legal_owner_name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Nombre de Instalación:</td>
                        <td colSpan={3}>{branch.name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Correo electrónico</td>
                        <td>{text(branch.email)}</td>
                        <td className='bg-light bold'>Dirección</td>
                        <td>{branch.address_line}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Departamento</td>
                        <td>{branch.address_state}</td>
                        <td className='bg-light bold'>Ciudad</td>
                        <td>{branch.address_city}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Teléfono Fijo</td>
                        <td>{text(branch.phone)}</td>
                        <td className='bg-light bold'>Celular</td>
                        <td>{text(branch.mobile)}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>2.1. INFORMACIÓN COMPLEMENTARIA</SectionTitle>
            <table className='table all_borders mb-0'>
                <tbody>
                    <tr>
                        <td width="260px" className='bg-light bold'>Nombre de quien recibe la inspección:</td>
                        <td>{data.received_by ? `${service.proposal.inquiry.employee.name} / ${data.received_by}` : service.proposal.inquiry.employee.name}</td>
                        <td width="120px" className='bg-light bold'>Cargo:</td>
                        <td>{service.proposal.inquiry.employee.position}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Correo electrónico</td>
                        <td>{data.received_by_email ? data.received_by_email : text(service.proposal.inquiry.employee.email)}</td>
                        <td className='bg-light bold'>Celular/Teléfono</td>
                        <td>{text(service.proposal.inquiry.employee.phone)}{" - "}
                        {service.proposal.inquiry.employee.mobile}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Bandera</td>
                        <td colSpan={3}>{text(service.proposal.inquiry.branch_spec.branch.flag)}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>3. DECLARACIÓN DE INDEPENDENCIA DEL INSPECTOR</SectionTitle>
            <SectionContent className="small">
                "En calidad de inspector asignado a la actividad declaro que en un lapso no inferior a los (2) años previos a la realización de la presente inspección, NO he realizado actividades en los ítems a inspeccionar de:<br/>
                -	Fabricación, Instalación y/o revestimiento  de tanques<br/>
                -	Instalación de tuberías<br/>
                También declaro que en el proceso de inspección seré imparcial y manejaré la información del proceso de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo evaluador de conformidad DEWAR S.A.S., para el desarrollo del proceso de inspección tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento.<br/>
                Así mismo, declaró no presentar conflicto de interés con el procedimiento  que me ha sido asignado como inspector, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como organismo de inspección y el Reglamento de Inspección."
            </SectionContent>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Nombre:</td>
                        <td>{service.service_inspector.user.name}</td>
                        <td className='bg-light bold'>Cédula</td>
                        <td>{service.service_inspector.user.document}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>4. EJECUCIÓN DE LA INSPECCIÓN</SectionTitle>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={2}>REUNIÓN DE APERTURA Y CIERRE</td>
                    </tr>
                    <tr>
                        <td className='small'>
                            1. Presentación de las partes. Explicar el rol del inspector asignado, quien estará frente procedimiento de pruebas de hermeticidad.<br />
                            2. Protocolo de seguridad y Alcance de la inspección<br />
                            3. Presentación del servicio de inspección– Explicar procedimiento de inspección (se indicará que durante el tiempo que dura la inspección, en el tanque y la tubería, se suspenderá la operación hasta finalizar la prueba)<br />
                            4. Explicar los tiempos del proceso, la revisión y toma de decisión.<br />
                            5. Ratificación confidencialidad.<br />
                            6. Ratificación Imparcialidad, Independencia y Transparencia<br />
                            7. Coordinación de recursos y materiales necesarios para la inspección (de ser necesario)<br />
                            8. Consultar sobre acciones que se deben tener en cuenta para el desarrollo de la inspección de manera segura.<br />
                            9. Preguntas y respuestas.<br />
					    </td>
                        <td className='small'>
                            1. Agradecimientos.<br />
                            2. Presentación general de los resultados.<br />
                            3. Procedimiento de Apelaciones, quejas o reclamo. Referencia Reglamento de Inspección.<br />
                        </td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle className="print-page-break">4.1. INFORMACIÓN ADICIONAL Y DE SEGURIDAD</SectionTitle>
            <table className='table all_borders'>
                <tbody>
                    <tr className='text-center'>
                        <td className='bg-light bold'>ASPECTOS A EVALUAR</td>
                        <td width="120px" className='bg-light bold'>RESPUESTA</td>
                        <td width="200px" className='bg-light bold'>OBSERVACIONES</td>
                    </tr>
                    <tr>
                        <td>a. ¿Conoce las acciones que se deben adelantar en caso de emergencia?</td>
                        <td>{parseInt(data.review_specs.has_security_actions) ? 'SI' : 'NO'}</td>
                        <td>{text(data.review_specs.security_actions_comments)}</td>
                    </tr>
                    <tr>
                        <td>b. ¿Se cuenta con las condiciones necesarias para realizar la inspección de manera segura?</td>
                        <td>{parseInt(data.review_specs.has_security_conditions) ? 'SI' : 'NO'}</td>
                        <td>{text(data.review_specs.security_conditions_comments)}</td>
                    </tr>
                    <tr>
                        <td>c. ¿Requiere de algún elemento adicional para realizar la inspección de forma segura?</td>
                        <td>{parseInt(data.review_specs.has_security_additionals) ? 'Si' : 'No'}</td>
						<td>{text(data.review_specs.security_additionals_comments)}</td>
                    </tr>
                    <tr>
                        <td>d. *¿Existe soporte técnico con recomendación por parte del fabricante sobre el procedimiento de prueba que se debe utilizar?</td>
                        <td>{parseInt(data.review_specs.has_tecnical_support) ? 'Si' : 'No'}</td>
						<td>{text(data.review_specs.tecnical_support_comments)}</td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <small>*Si la respuesta a la pregunta es SÍ, se debe registrar en las observaciones la presión recomedada por el fabricante, pero si este valor de presión NO garantiza la seguridad e integridad del tanque y/o tubería, DEWAR podrá abstenerse de realizar la prueba y se realizará con los citerios propios establecidos en caso de que el cliente lo apruebe.</small>
                        </td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>5. ELEMENTOS UTILIZADOS EN INSPECCIÓN</SectionTitle>
            <SectionTitle>5.1. Equipos de medición empleados en la inspección</SectionTitle>
            <table className='table all_borders'>
                <tbody>
                    <tr className='text-center'>
                        <td className='bg-light bold'>Ítem</td>
                        <td className='bg-light bold'>Equipo</td>
                        <td className='bg-light bold'>Marca</td>
                        <td className='bg-light bold'>Código Interno</td>
                        <td className='bg-light bold'>Serial</td>
                        <td className='bg-light bold'>Fecha de calibración / verificación</td>
                    </tr>
                    { data.review_equipments.map(reviewEquipment => (
                        <tr key={reviewEquipment.id}>
                            <td>{reviewEquipment.item}</td>
                            <td>{reviewEquipment.name}</td>
                            <td>{reviewEquipment.brand}</td>
                            <td>{reviewEquipment.code}</td>
                            <td>{reviewEquipment.serial}</td>
                            <td>{moment(reviewEquipment.verified_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                        </tr>
                    )) }
                </tbody>
            </table>
            <SectionTitle>6. PROCESO DE INSPECCIÓN Y RESULTADOS: PRUEBAS DE HERMETICIDAD A TANQUES Y TUBERIAS</SectionTitle>
            <SectionContent>Los datos recopilados durante la ejecución de la inspección se incluyen en los anexos: bitacoras de inspección de pruebas de hermeticidad de tanques y bitácora de inspección de pruebas de tuberías.</SectionContent>
            <SectionTitle>Tanques  Inspeccionados</SectionTitle>
            <table className='table all_borders'>
                <tbody>
                    <tr className='text-center bg-light bold' style={{ verticalAlign: 'middle' }}>
                        <td width="75px">Nº Tanque</td>
                        <td width="75px">Identificación del tanque</td>
                        <td width="75px">Capacidad (gal)</td>
                        <td width="75px">Producto</td>
                        <td width="140px">Cumple/No Cumple</td>
                        <td>Observaciones</td>
                        <td>Fecha Inspección</td>
                    </tr>
                    { data.review_logbooks.filter(r => r.spec_type === 'tank').map( reviewLogbook => (
                        <tr key={reviewLogbook.id}>
                            <td>{reviewLogbook.spec_number}</td>
                            <td>{text(reviewLogbook.spec_details.identificacion_tanque)}</td>
                            <td>{text(reviewLogbook.spec_details.capacidad)}</td>
                            <td>{reviewLogbook.product_name} {reviewLogbook.product_other_name}</td>
                            <td>{parseResults(reviewLogbook)}</td>
                            <td>{parseObservations(reviewLogbook)}</td>
                            <td>{reviewLogbook.register_at}</td>
                        </tr>
                    )) }
                    <tr className='text-center bg-light bold'>
                        <td width="50px">Nº Tubería</td>
                        <td>Desde el tanque</td>
                        <td>Hacia</td>
                        <td>Producto</td>
                        <td>Cumple/No Cumple</td>
                        <td>Observaciones</td>
                        <td>Fecha Inspección</td>
                    </tr>
                    { data.review_logbooks.filter(r => r.spec_type === 'pipeline').map( reviewLogbook => (
                        <tr key={reviewLogbook.id}>
                            <td>{reviewLogbook.spec_number}</td>
                            <td>{reviewLogbook.spec_details.desde_tanque}</td>
                            <td>{reviewLogbook.spec_details.hacia}</td>
                            <td>{reviewLogbook.product_name} {reviewLogbook.product_other_name}</td>
                            <td>{parseResults(reviewLogbook)}</td>
                            <td>{parseObservations(reviewLogbook)}</td>
                            <td>{reviewLogbook.register_at}</td>
                        </tr>
                    )) }
                </tbody>
            </table>
            <SectionTitle className="print-page-break">6.1. RESULTADOS CON CUMPLIMIENTO</SectionTitle>
            <SectionContent>{data.review_specs.results_if_compliant}</SectionContent>
            <SectionTitle>6.2. RESULTADOS SIN CUMPLIMIENTO</SectionTitle>
            <SectionContent>{data.review_specs.results_if_not_compliant}</SectionContent>
            <SectionTitle>6.3. OBSERVACIONES DEL INSPECTOR</SectionTitle>
            <SectionContent>{text(data.observations)}</SectionContent>
            <SectionTitle>7. DECLARACIÓN FINALIZACIÓN DEL SERVICIO</SectionTitle>
            <SectionContent>{data.review_specs.final_declaration}</SectionContent>
            <SectionTitle>8. RATIFICACIÓN DEL PRINCIPIO DE IMPARCIALIDAD E INDEPENDENCIA</SectionTitle>
            <SectionContent>Si la instalación ha recibido servicios por alguna de las entidades relacionadas identificadas previamente por DEWAR, escriba el nombre y diligencie la Ratificación del principio de imparcialidad e independencia FRN34</SectionContent>
        </Container>
    )
}

const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
    & table.table {
        margin-bottom: 3px;
    }
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
`

const SectionTitle = styled.div`
	font-weight: bold;
	padding: 2px 6px;
	margin: 10px 0px 5px;
	color: black;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const SectionContent = styled.div`
	padding: 5px;
	border: 1px solid black;
	color: black;
    white-space: pre-line;
`