import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Descriptions, Divider, Empty } from 'antd'
import { Button } from 'reactstrap'
import moment from 'moment'

import NewCertificateModal from './partials/NewCertificateModal'
import EditCertificateModal from './partials/EditCertificateModal'
import CertificateTicketsModal from './partials/tickets/CertificateTicketsModal'
import SendNotificationButton from '../../components/SendNotificationButton'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

import { notifyCertificate } from './services'

const { Item } = Descriptions

export default function CertificateTab({ serviceReport, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)
	const [showCertificateTicketsModal, setShowCertificateTicketsModal] = useState(false)

	const certificate = serviceReport.service_veredict.certificate
	
	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Certificado de Inspección</h6>
			{ certificate ? (
				<React.Fragment>
					<Descriptions bordered size="small" className='mt-20' column={2}>
						<Item label="Versión">
							{certificate.format_version.sku}
						</Item>
						<Item label="Fecha de Expedición">
							{moment(certificate.issued_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Razón Social" span={2}>
							{certificate.company_name}
						</Item>
						<Item label="NIT">
							{certificate.company_document}
						</Item>
						<Item label="Código SICOM">
							{certificate.company_sicom}
						</Item>
						<Item label="Observaciones" span={2}>
							{certificate.observations ? certificate.observations : <em>Sin observaciones</em>}
						</Item>
						<Item label="Adjunto" span={2}>
							{certificate.attachment_url ? <a href={certificate.attachment_url} target="_blank" rel="noreferrer">{certificate.attachment_url}</a> : <em>Sin adjunto</em>}
						</Item>
						<Item label="Fecha de Publicación" span={2}>
							{certificate.published_at ? moment(certificate.published_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin publicar aún</em>}
						</Item>
					</Descriptions>
					<div className='text-right'>
						<p className='text-link mt-20 mb-10' onClick={() => setShowEditModal(true)}>
							Modificar Certificado
						</p>
					</div>
					<Link to={`/certificates/preview/${certificate.id}`}>
						<Button color="primary" outline>
							Ver Certificado de Inspección
						</Button>
					</Link>
					<Divider type="vertical" />
					<SendNotificationButton notifyService={() => notifyCertificate(certificate.id)} />
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(certificate.id)}>
						Correos Enviados
					</Button>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setShowCertificateTicketsModal(true)}>
						Novedades Recibidas
					</Button>
				</React.Fragment>
			) : (
				<React.Fragment>
					{ serviceReport.is_committee_compliant === 1 || serviceReport.format_version.business_scope === 'pruebas' ? (
						<Empty description="No se ha generado el certificado aún" className='pt-30'>
							<Button color="primary" onClick={() => setShowNewModal(true)}>
								Generar Certificado de Inspección
							</Button>
						</Empty>
					) : serviceReport.is_committee_compliant === 1 ? (
						<Empty className='pt-30' description="La decisión del comité fue de no otorgar el certificado" />
					) : (
						<Empty className='pt-30' description="La decisión del comité aún no ha sido registrada" />
					)}
				</React.Fragment>
			)}
			{ showNewModal && (
				<NewCertificateModal 
					visible
					onClose={() => setShowNewModal(false)}
					businessScope={serviceReport.format_version.business_scope}
					serviceVeredictId={serviceReport.service_veredict.id}
					service={serviceReport.service_review.service_plan.service_inspector.service}
					reload={reload}
				/>
			)}
			{ showEditModal && (
				<EditCertificateModal 
					visible
					onClose={() => setShowEditModal(false)}
					certificate={certificate}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="certificate"
					modelId={emailLogsModal}
				/>
			)}
			{ showCertificateTicketsModal && (
				<CertificateTicketsModal 
					visible
					onClose={() => setShowCertificateTicketsModal(false)}
					certificate={certificate}
				/>
			)}
		</React.Fragment>
	)
}