import React from 'react'

import DECISION_NOTICE_TEMPLATE_INS01 from './templates/DECISION_NOTICE_TEMPLATE_INS01'
import DECISION_NOTICE_TEMPLATE_PRU01 from './templates/DECISION_NOTICE_TEMPLATE_PRU01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function DecisionNoticeDocument({ decisionNotice }) {
	
	switch (decisionNotice.format_version.format_template) {
		case 'DECISION_NOTICE_TEMPLATE_INS01':
			return <DECISION_NOTICE_TEMPLATE_INS01 data={decisionNotice} />

		case 'DECISION_NOTICE_TEMPLATE_PRU01':
			return <DECISION_NOTICE_TEMPLATE_PRU01 data={decisionNotice} />
	
		default:
			return <DocumentTemplate404 />
	}

}