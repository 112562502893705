import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchPendingProposals } from '../../Proposals/services'

export default function ServiceForm({ register, errors, service, selectedProposal, setSelectedProposal, businessScope }) {
	return (
		<React.Fragment>
			{ selectedProposal && (
				<Form.Group>
					<Form.Label>Propuesta Comercial <span className="text-danger">*</span></Form.Label>
					<ServerSideSelect
						value={selectedProposal}
						placeholder="Buscar por numero de propuesta"
						fetchOptions={searchPendingProposals}
						onChange={value => setSelectedProposal(value)}
						scopeId={businessScope}
						className="full-width mb-10"
					/>
					<Link to="/proposals" className='small mb-5 block'>Ir a módulo de propuestas</Link>
				</Form.Group>
			)}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Horas Aproximadas <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('hours_estimate', { required: true })}
					/>
					{ errors.hours_estimate && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha de Inicio <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						type="date"
						{...register('start_at', { required: true })}
					/>
					{ errors.start_at && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Observaciones/ Observadores <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('observers', { required: true })}
					style={{ height: '70px' }}
				/>
			</Form.Group>
		</React.Fragment>
	)
}