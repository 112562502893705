import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditEmployeeModal from './EditEmployeeModal'

import { deleteEmployee } from '../services'
import { handleError } from '../../../helpers'

export default function EmployeesTable({ employees, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editEmployee, setEditEmployee] = useState(null)

	const handleDelete = id => {
		deleteEmployee(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Cargo',
			dataIndex: 'position',
		},
		{
			title: 'Correo',
			dataIndex: 'email',
			render: t => t ? t : <em className='hint-text'>Sin email registrado</em>
		},
		{
			title: 'Sede Referencia',
			dataIndex: 'reference_location',
			render: t => t ? t : <em className='hint-text'>Sin registro</em>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditEmployee(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
							<span className='text-link'>Editar</span>
						</div>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el empleado?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								<span className='text-link'>Eliminar</span>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRow = r => (
		<React.Fragment>
			<p className='mb-10'><strong>Celular:</strong> {r.mobile ? r.mobile : <em className='hint-text'>Sin celular registrado</em>}</p>
			<p className='mb-0'><strong>Fijo:</strong> {r.phone ? r.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				rowKey={r => r.id}
				dataSource={employees}
				size='small'
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
			/>
			{ editEmployee && (
				<EditEmployeeModal 
					visible
					onClose={() => setEditEmployee(null)}
					employee={editEmployee}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}