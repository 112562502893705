import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Divider, message, Tooltip } from 'antd'
import { Spinner } from 'reactstrap'

import { checkConnection } from '../actions'
import { unqueueRequest } from '../../Offline/actions'

import dewarApi from '../../../services/dewarApi'
import { storeSyncLog } from '../../SyncLogs/services'
import { handleError } from '../../../helpers'

export default function NetworkStatusWidget() {
	const [loading, setLoading] = useState(false)
	const [loadingSync, setLoadingSync] = useState(false)
	const { isConnected, queue } = useSelector(state => state.offline)
	const user_id = useSelector(state => state.auth.user.id)
	const dispatch = useDispatch()
	const history = useHistory()

	const onRefresh = () => {
		setLoading(true)
		setTimeout(() => {
			dispatch(checkConnection())
			setLoading(false)
		}, 700)
	}

	const onSyncFlush = () => {
		if(!navigator.onLine) 
			return message.warning('Requiere de internet para esta acción')

		if(queue.length === 0)
			return message.info('No tiene procesos para sincronizar')

		setLoadingSync(true)
		for (let i = 0; i < queue.length; i++) {
			const process = queue[i]
			let request = null
			if(process.method === 'PUT') {
				request = dewarApi().put(process.url, process.data)
			} else if(process.method === 'POST') {
				request = dewarApi().post(process.url, process.data)
			} else if(process.method === 'DELETE') {
				request = dewarApi().delete(process.url, process.data)
			}
			request.then(() => dispatch(unqueueRequest(process.key)))
				.then(() => {if(i === queue.length - 1) history.push('/services/inspeccion')})
				.catch(error => {
					storeSyncLog({ 
						user_id , 
						type:'Sincronización manual desde administrador', 
						payload: JSON.stringify(process) 
					}).catch(error2 => handleError(error2))
					handleError(error)
				})
		}
		setLoadingSync(false)
		message.success('Sincronización finalizada.')
	}

	return (
		<React.Fragment>
			<Tooltip placement='bottom' title="Verificar Internet">
				<Container onClick={!loading ? onRefresh : ()=>{}}>
					<Signal status={isConnected} />
					{ isConnected ? 'En Linea' : 'Sin Internet' }
					{ loading && <Spinner size="sm" style={{ width:'15px', height:'15px', marginLeft:'5px' }} /> }
				</Container>
			</Tooltip>
			<Divider type="vertical" style={{ borderColor: 'white' }} />
			<Tooltip placement='bottom' title="Sincronizar Pendientes">
				<Container onClick={!loadingSync ? onSyncFlush : ()=>{}}>
					<Signal status={queue.length === 0} />
					{queue.length} acciones por SYNC
					{ loadingSync && <Spinner size="sm" style={{ width:'15px', height:'15px', marginLeft:'5px' }} /> }
				</Container>
			</Tooltip>
		</React.Fragment>
	)
}

const Container = styled.div`
	border: 1px solid white;
	border-radius: 20px;
	padding: 5px 8px;
	color: white;
	display: inline;
	&:hover {
		background-color: white;
		color: #e78035;
		cursor: pointer;
	}
`

const Signal = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 15px;
	background-color: ${props => props.status ? 'green' : 'black' };
	display: inline-block;
	margin-right: 5px;
`