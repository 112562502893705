import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServices = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/services', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getService = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/services/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeService = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/services', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateService = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/services/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteService = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/services/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServices = async (term, token) => (
	fetch( getDomain()+`/services?filter[id]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service => ({
				label: `#${service.id} - ${service.proposal_sku}`,
				value: service.id,
			})),
		)
)

export const getForSyncService = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/services/${id}`, { params: {
				include: 'proposal.inquiry.branchSpec.branch.company,serviceInspector.serviceNotification,verificationList,serviceInspector.user,serviceInspector.servicePlan.serviceReview.impartialityNotice,initialService.verificationList,retryService,serviceInspector.servicePlan.serviceReview.nonCompliantReport,serviceInspector.servicePlan.serviceReview.retryNotice,serviceInspector.servicePlan.serviceReview.reviewEquipments,proposal.inquiry.employee,serviceInspector.servicePlan.serviceReview.islandSpecs,serviceInspector.servicePlan.serviceReview.tankSpecs,verificationList.verificationTasks.taskTemplate,serviceInspector.servicePlan.serviceReview.reviewLogbooks.formatVersion'
			 }})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const serviceNotifyDirector = (data, id) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/services/${id}/notify_director`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)