import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Redirect } from 'react-router'

import ListTopBar from '../../components/ListTopBar'
import ServicesTable from '../Services/partials/ServicesTable'

export default function ServicesListOffline({ businessScope }) {
	const { isConnected } = useSelector(state => state.offline)
	const services = useSelector(state => state.services.data.filter(s => s.business_scope === businessScope))

	if(isConnected) return <Redirect to={`/services/${businessScope}`} />

	return (
		<React.Fragment>
			<ListTopBar 
				title={`Servicios de ${businessScope === 'pruebas' ? 'Pruebas' : 'Inspección'} - OFFLINE`}
				subtitle="Visualizando únicamente servicios disponibles offline"
			/>
			<Card>
				<CardBody>
					<ServicesTable 
						services={services} 
						businessScope={businessScope} 
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}