import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import ReviewLogbookSpecDetailsForm from './forms/ReviewLogbookSpecDetailsForm'
import ReviewLogbookSurveyAnswersForm from './forms/ReviewLogbookSurveyAnswersForm'
import ReviewLogbookWellTestForm from './forms/ReviewLogbookWellTestForm'
import ReviewLogbookTightnessTestsForm from './forms/ReviewLogbookTightnessTestsForm'
import ReviewLogbookRequirementsSurveyForm from './forms/ReviewLogbookRequirementsSurveyForm'
import ReviewLogbookDirectorVeredictForm from './forms/ReviewLogbookDirectorVeredictForm'
import NewReviewLogbookForm from './forms/NewReviewLogbookForm'

import { offlineUpdateReviewLogbook } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'

import { updateReviewLogbook } from '../services'
import { handleError } from '../../../helpers'

export default function EditReviewLogbookModal({ visible, onClose, reviewLogbook, serviceId, reload, formKey }) {
	const { isConnected } = useSelector(state => state.offline)
    const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            register_at: reviewLogbook.register_at,
            spec_type: reviewLogbook.spec_type,
            spec_category_slug: reviewLogbook.spec_category_slug,
            spec_number: reviewLogbook.spec_number,
            product_name: reviewLogbook.product_name,
            product_other_name: reviewLogbook.product_other_name,
            spec_details: {...reviewLogbook.spec_details},
            survey_answers: {...reviewLogbook.survey_answers},
            wells_tests: {...reviewLogbook.wells_tests},
            tightness_tests: {...reviewLogbook.tightness_tests},
            requirements_survey: {...reviewLogbook.requirements_survey},
        }
    })

    const onSubmit = values => {
        setLoading(true)

        values.register_at = moment(values.register_at).format('YYYY-MM-DD HH:mm:ss')

        if(isConnected) {
            updateReviewLogbook(reviewLogbook.id, values)
                .then(res => {
                    message.success(res.data.message)
                    reload()
                    onClose()
                })
                .catch(error => handleError(error, setLoading))
        } else {
            dispatch(offlineUpdateReviewLogbook(values, serviceId, reviewLogbook.id))
			dispatch(queueRequest({ url: `/review_logbooks/${reviewLogbook.id}`, data: values }))
			message.info('Actualización en espera de internet')
			onClose()
        }
    }

    const formProps = {
        register,
        errors,
        watch,
        specType: reviewLogbook.spec_type
    }

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Actualizar Bitacora de Inspección</ModalHeader>
            <ModalBody>
                { formKey === 'wellsTests' ? (
                    <ReviewLogbookWellTestForm 
                        onClose={onClose}
                        reload={reload}
                        reviewLogbook={reviewLogbook} 
                    />
                ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        { formKey === 'specDetails' && <NewReviewLogbookForm {...formProps} onlyEditable /> }
                        { formKey === 'specDetails' && <ReviewLogbookSpecDetailsForm {...formProps} /> }
                        { formKey === 'surveyAnswers' && <ReviewLogbookSurveyAnswersForm {...formProps} specCategory={reviewLogbook.spec_category_slug} /> }
                        { formKey === 'tightnessTests' && <ReviewLogbookTightnessTestsForm {...formProps} setValue={setValue} /> }
                        { formKey === 'requirementsSurvey' && <ReviewLogbookRequirementsSurveyForm {...formProps} /> }
                        { formKey === 'directorVeredict' && <ReviewLogbookDirectorVeredictForm {...formProps} /> }
                        <Button color='primary' type="submit" disabled={loading}>
                            { loading && <Spinner animation='border' size="sm" /> }
                            Actualizar Bitacora
                        </Button>
                    </Form>
                )}
            </ModalBody>
        </Modal>
    )
}