import React from 'react'
import { useForm } from 'react-hook-form'
import { message } from 'antd'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import UserEquipmentForm from './UserEquipmentForm'

import { updateUserEquipment } from '../services'
import { handleError } from '../../../helpers'

export default function EditUserEquipmentModal({ visible, onClose, userEquipment, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			business_scope: userEquipment.business_scope,
			item: userEquipment.item,
			name: userEquipment.name,
			brand: userEquipment.brand,
			serial: userEquipment.serial,
			code: userEquipment.code,
			verified_at: moment(userEquipment.verified_at).format('YYYY-MM-DD'),
		}
	})

	const onSubmit = values => {
		updateUserEquipment(userEquipment.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Equipo de Medición</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserEquipmentForm register={register} errors={errors} />
						<Button color='primary' type='submit'>Actualizar Equipo</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}