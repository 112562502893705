import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'
import { currency, round } from '../../../helpers'

export default function PreInvoiceForm({ register, errors, watch, setValue, isDirty, proposal, taxesFactor, setTaxesFactor }) {
	const totalBeforeTaxes = isDirty ? parseInt(watch('service_value')) * parseInt(watch('quantity')) + parseInt(watch('trip_expenses_value')) : proposal.service_value + proposal.trip_expenses_value

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label className='mt-0'>Versión</Form.Label>
				<FormatVersionSelect 
					keyword="preInvoice"
					setValue={setValue}
					businessScope={proposal.format_version.business_scope}
				/>
				{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vigencia</Form.Label>
					<Form.Control {...register('expire_at')} disabled />
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Elaboración</Form.Label>
					<Form.Control 
						{...register('elaborated_at', { required: true })}
						type="date"
					/>
					{ errors.elaborated_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Por concepto de:</Form.Label>
				<Form.Control 
					{...register('details', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '100px' }}
				/>
				{ errors.details && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col} sm="8">
					<Form.Label>Valor por Unidad</Form.Label>
					<Form.Control 
						{...register('service_value', { required: true })}
						defaultValue={proposal.service_value}
					/>
					{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Cantidad <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('quantity', { min:1, required: true })}
						defaultValue={proposal.quantity}
					/>
					{ errors.quantity && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Valor del Servicio</Form.Label>
					<Form.Control 
						disabled
						value={`${currency(round(watch('service_value')*watch('quantity'), -1))} COP`}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Total Gastos de Viaje</Form.Label>
					<Form.Control 
						{...register('trip_expenses_value', {})}
						defaultValue={proposal.trip_expenses_value}
					/>
					{ errors.trip_expenses_value && <Form.Text className='text-warning'>Este campo debe ser númerico</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Descuento SOLDICOM <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register('soldicom_value', { })}
						defaultValue={0}
					/>
					{ errors.soldicom_value && <Form.Text className='text-warning'>Este campo debe ser númerico</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Total Impuestos</Form.Label>
					<Form.Control 
						value={`$ ${currency(round(totalBeforeTaxes*taxesFactor,1))}`} 
						disabled 
					/>
					<p className='text-link mb-0' onClick={() => setTaxesFactor(taxesFactor ? 0 : 0.19)}>
						{ taxesFactor ? 'Quitar IVA' : 'Agregar IVA' }
					</p>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Valor Total</Form.Label>
				<Form.Control 
					disabled
					value={watch('soldicom_value') ? `$ ${currency(round((totalBeforeTaxes*(1+taxesFactor)) - parseInt(watch('soldicom_value')), 1))} COP` : `$ ${currency(round((totalBeforeTaxes*(1+taxesFactor)),1))} COP`}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones:</Form.Label>
				<Form.Control 
					{...register('observations', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '100px' }}
				/>
				{ errors.observations && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}