import axios from "axios"

import { store } from "../store"

export const getUri = () => {
   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'dewar_api.test' : ( (url[0].includes('admin')) ? 'api.dewar.com.co' : 'apipruebas.dewar.com.co')

   return `https://${domain}`
}

export const getDomain = () => {
   var uri = getUri()
   
   return `${uri}/api`
}

/*
 * Instancia con el servicio principal del backend
 */
const dewarApi = () => {
   const state = store.getState();
   const authToken = state.auth.token;

   return axios.create({
      baseURL: getDomain(),
      timeout: 60000,
      headers: {
         Authorization: `Bearer ${authToken}`,
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default dewarApi;
