import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function ServicePlanForm({ register, errors, setValue, onlyEditable, businessScope }) {
	return (
		<React.Fragment>
			{ !onlyEditable && (
				<React.Fragment>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
							<FormatVersionSelect 
								keyword="servicePlan"
								setValue={setValue}
								businessScope={businessScope}
							/>
							{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
							<Form.Control {...register('expire_at')} disabled />
						</Form.Group>
					</Row>
					{ businessScope === 'inspeccion' && (
						<Form.Group>
							<Form.Label>Lugar <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register('location', { required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option>Sede Inspector</option>
								<option>En sitio de la instalación</option>
							</Form.Control>
							{ errors.location && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
					)}
					<Form.Group>
						<Form.Label>Fecha envío del plan <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							{...register('sent_at', { required: true })}
							type="date"
						/>
						{ errors.sent_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
				</React.Fragment>
			)}
			<Form.Group>
				<Form.Label>Fecha de la Inspección <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('inspected_at', { required: true })}
					type="date"
				/>
				{ errors.inspected_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones: <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('observations', { required: true })}
					as="textarea"
					style={{ height: '120px', whiteSpace: 'pre-line' }}
				/>
				{ errors.observations && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}