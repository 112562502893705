import React, { useState } from 'react'
import { useSelector } from 'react-redux' 
import { Descriptions } from 'antd'
import moment from 'moment'

import ReportInspectorResultModal from './modals/ReportInspectorResultModal'
import ReportCommitteeResultModal from './modals/ReportCommitteeResultModal'
import ReportDirectorResultModal from './modals/ReportDirectorResultModal'
import AssignReportInspectorModal from './modals/AssignReportInspectorModal'
import ReportResultsDetailsModal from './modals/ReportResultsDetailsModal'

import { text } from '../../../helpers'

const { Item } = Descriptions

export default function ReportDecisionDetails({ serviceReport, reload, editable }) {
	const user = useSelector(state => state.auth.user)
	const [showInspectorResultModal, setShowInspectorResultModal] = useState(false)
	const [showDirectorResultModal, setShowDirectorResultModal] = useState(false)
	const [showCommitteeResultModal, setShowCommitteeResultModal] = useState(false)
	const [showAssignInspectorModal, setShowAssignInspectorModal] = useState(false)
	const [showResultsDetailsModal, setShowResultsDetailsModal] = useState(false)

	switch (serviceReport.format_version.business_scope) {
		case 'inspeccion':
			return (
				<React.Fragment>
					<p className='bold mt-15'>DECISIÓN DE DIRECTOR TÉCNICO</p>
					<Descriptions bordered size="small" column={1}>
						<Item label="Director (a) Técnico (a) miembro Comité Técnico:">
							{ serviceReport.director_name ? serviceReport.director_name : <em>Sin específicar</em> }
						</Item>
						<Item label="La instalación, respecto a los criterios de inspección se encuentra:">
							{serviceReport.is_director_compliant === 1 ? 'Conforme' : serviceReport.is_director_compliant === 0 ? 'No Conforme' : <em>Sin registro</em>}
						</Item>
						<Item label="Observaciones adicionales:">
							{ serviceReport.director_observations ? serviceReport.director_observations : <em>Sin observaciones</em> }
						</Item>
					</Descriptions>
					{ editable && user.id === serviceReport.director_user_id && (
						<div className='text-right mt-20'>
							<p className='text-link' onClick={() => setShowDirectorResultModal(true)}>
								Registrar resultado de director
							</p>
							<p className='text-link' onClick={() => setShowAssignInspectorModal(true)}>
								Asignar Inspector del Comité
							</p>
						</div>
					)}
					<p className='bold mt-15'>DECISIÓN DE INSPECTOR</p>
					<Descriptions bordered size="small" column={1}>
						<Item label="Inspector (a):">
							{ serviceReport.service_review.service_plan.service_inspector.user.name }
						</Item>
						<Item label="La instalación, respecto a los criterios de inspección se encuentra:">
							{serviceReport.service_review.is_compliant === 1 ? 'Conforme' : serviceReport.service_review.is_compliant === 0 ? 'No Conforme' : <em>Sin registro</em>}
						</Item>
						<Item label="Observaciones adicionales:">
							{ serviceReport.service_review.observations ? serviceReport.service_review.observations : <em>Sin observaciones</em> }
						</Item>
					</Descriptions>
					{ editable && user.id === serviceReport.inspector_user_id && (
						<div className='text-right mt-20'>
							<p className='text-link' onClick={() => setShowInspectorResultModal(true)}>
								Registrar resultado de inspector
							</p>
						</div>
					)}
					<p className='bold mt-15'>DECISIÓN COMITÉ TÉCNICO</p>
					<Descriptions bordered size="small" column={2} className='mb-5'>
						<Item label="Consecutivo Comité Técnico:">
							{serviceReport.committee_sku_formated}
						</Item>
						<Item label="Fecha de Comité:">
							{ serviceReport.committee_at ? moment(serviceReport.committee_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin registro</em> }
						</Item>
					</Descriptions>
					<Descriptions bordered size="small" column={1}>
						<Item label="El Comité Técnico decide que la Instalación objeto del proceso de inspección es:">
							{serviceReport.is_committee_compliant === 1 ? 'Conforme' : serviceReport.is_committee_compliant === 0 ? 'No Conforme' : <em>Sin registro</em>}
						</Item>
						<Item label="Observaciones adicionales:">
							{ serviceReport.committee_observations ? serviceReport.committee_observations : <em>Sin observaciones</em> }
						</Item>
					</Descriptions>
					{ editable && user.role === 'admin' && (
						<div className='text-right mt-20'>
							<p className='text-link' onClick={() => setShowCommitteeResultModal(true)}>
								Registrar decisión de comite
							</p>
						</div>
					)}
					
					{ showAssignInspectorModal && (
						<AssignReportInspectorModal 
							visible
							onClose={() => setShowAssignInspectorModal(false)}
							serviceReport={serviceReport}
							reload={reload}
						/>
					)}
					{ showInspectorResultModal && (
						<ReportInspectorResultModal 
							visible
							onClose={() => setShowInspectorResultModal(false)}
							serviceReport={serviceReport}
							reload={reload}
						/>
					)}
					{ showDirectorResultModal && (
						<ReportDirectorResultModal 
							visible
							onClose={() => setShowDirectorResultModal(false)}
							serviceReport={serviceReport}
							reload={reload}
						/>
					)}
					{ showCommitteeResultModal && (
						<ReportCommitteeResultModal 
							visible
							onClose={() => setShowCommitteeResultModal(false)}
							serviceReport={serviceReport}
							reload={reload}
						/>
					)}
				</React.Fragment>
			)

		case 'pruebas':
			return (
				<React.Fragment>
					<p className='bold mt-20'>RESULTADOS Y OBSERVACIONES</p>
					<Descriptions bordered size="small" className='mt-15' column={1}>
						<Item label="RESULTADOS PRUEBA HERMETICIDAD TUBERÍAS">{text(serviceReport.results_details.prueba_hermeticidad)}</Item>
						<Item label="RESULTADOS CONFORME">{text(serviceReport.results_details.resultados_conforme)}</Item>
						<Item label="RESULTADOS NO CONFORME">{text(serviceReport.results_details.resultados_no_conforme)}</Item>
						<Item label="OBSERVACIONES ADICIONALES">{text(serviceReport.results_details.observaciones_adicionales)}</Item>
					</Descriptions>
					<p className='text-link text-right mt-15 mb-20' onClick={() => setShowResultsDetailsModal(true)}>
						Actualizar Resultados y Observaciones
					</p>
					{ showResultsDetailsModal && (
						<ReportResultsDetailsModal 
							visible
							onClose={() => setShowResultsDetailsModal(false)}
							serviceReport={serviceReport}
							reload={reload}
						/>
					)}
				</React.Fragment>
			)
	
		default:
			return;
	}
}