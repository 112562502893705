import React from 'react'

import { text } from '../../../../helpers';

export default function ReviewLogbookSurveyAnswersTable({ reviewLogbook }) {
    switch (reviewLogbook.spec_type) {
        case 'tank':
            return (
                <React.Fragment>
                    <table className='table all_borders compact_table'>
                        <tbody>
                            <tr>
                                <td className='bg-light bold' width="50px">Item</td>
                                <td className='bg-light bold'>Pregunta</td>
                                <td className='bg-light bold' width="100px">Respuesta</td>
                                <td className='bg-light bold' width="250px">Observaciones</td>
                            </tr>
                            <tr>
                                <td>1.1</td>
                                <td>¿Ha tenido durante el último inventario algún registro de pérdida de volumen?</td>
                                <td>{text(reviewLogbook.survey_answers.perdida_volumen)}</td>
                                <td>{text(reviewLogbook.survey_answers.perdida_volumen_obs)}</td>
                            </tr>
                            <tr>
                                <td>1.2</td>
                                <td>¿Se han realizado modificaciones al cuerpo del tanque o a la instalación de combustible?</td>
                                <td>{text(reviewLogbook.survey_answers.modificaciones_cuerpo)}</td>
                                <td>{text(reviewLogbook.survey_answers.modificaciones_cuerpo_obs)}</td>
                            </tr>
                            <tr>
                                <td>1.3</td>
                                <td>¿Ha presentado algún ingreso constante de agua?</td>
                                <td>{text(reviewLogbook.survey_answers.ingreso_agua)}</td>
                                <td>{text(reviewLogbook.survey_answers.ingreso_agua_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.1</td>
                                <td>¿Existe TE en tubería de desfogue?</td>
                                <td>{text(reviewLogbook.survey_answers.tuberia_desfogue)}</td>
                                <td>{text(reviewLogbook.survey_answers.tuberia_desfogue_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.2</td>
                                <td>¿Se prueba tubería de desfogue?</td>
                                <td>{text(reviewLogbook.survey_answers.prueba_tuberia_desfoge)}</td>
                                <td>{text(reviewLogbook.survey_answers.prueba_tuberia_desfoge_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.3</td>
                                <td>¿Se tapona la TE?</td>
                                <td>{text(reviewLogbook.survey_answers.tapona_te)}</td>
                                <td>{text(reviewLogbook.survey_answers.tapona_te_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.4</td>
                                <td>Tipo de llenado</td>
                                <td colSpan={2}>{text(reviewLogbook.survey_answers.tipo_llenado)}</td>
                            </tr>
                            <tr>
                                <td>2.5</td>
                                <td>¿Tiene Spill container?</td>
                                <td>{text(reviewLogbook.survey_answers.spill_container)}</td>
                                <td>{text(reviewLogbook.survey_answers.spill_container_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.6</td>
                                <td>¿Se Prueba tubería de llenado?</td>
                                <td>{text(reviewLogbook.survey_answers.prueba_llenado)}</td>
                                <td>{text(reviewLogbook.survey_answers.prueba_llenado_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.7</td>
                                <td>¿Tiene sonda de medición?</td>
                                <td>{text(reviewLogbook.survey_answers.sonda_medicion)}</td>
                                <td>{text(reviewLogbook.survey_answers.sonda_medicion_obs)}</td>
                            </tr>
                            <tr>
                                <td>2.8</td>
                                <td>Se instala equipo de prueba:</td>
                                <td colSpan={2}>{text(reviewLogbook.survey_answers.equipo_prueba)}</td>
                            </tr>
                            <tr>
                                <td>2.12</td>
                                <td>Se verifica cierre de válvula de bomba sumergible</td>
                                <td>{text(reviewLogbook.survey_answers.cierre_valvula)}</td>
                                <td>{text(reviewLogbook.survey_answers.cierre_valvula_obs)}</td>
                            </tr>
                            <tr>
                                <td>3.1</td>
                                <td>¿El Cliente desactivo la bomba sumergible?</td>
                                <td>{text(reviewLogbook.survey_answers.descativo_bomba)}</td>
                                <td>{text(reviewLogbook.survey_answers.descativo_bomba_obs)}</td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            )

        case 'pipeline':
            return (
                <React.Fragment>
                    <table className='table all_borders compact_table'>
                        <tbody>
                            <tr>
                                <td className='bg-light bold'>Pregunta</td>
                                <td className='bg-light bold' width="100px">Respuesta</td>
                                <td className='bg-light bold' width="250px">Observaciones</td>
                            </tr>
                            { reviewLogbook.spec_category_slug === 'dispensadores' && (
                                <tr>
                                    <td>Verificación de cierre de válvula de bomba sumergible</td>
                                    <td>{text(reviewLogbook.survey_answers.verifica_cierre)}</td>
                                    <td>{text(reviewLogbook.survey_answers.verifica_cierre_obs)}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Verificación de activación de válvula de impacto</td>
                                <td>{text(reviewLogbook.survey_answers.verifica_activacion)}</td>
                                <td>{text(reviewLogbook.survey_answers.verifica_activacion_obs)}</td>
                            </tr>
                            <tr>
                                <td>Verificación de tubería desconectada y taponada</td>
                                <td>{text(reviewLogbook.survey_answers.verifica_desconectada)}</td>
                                <td>{text(reviewLogbook.survey_answers.verifica_desconectada_obs)}</td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            )
    
        default:
            return;
    }
}