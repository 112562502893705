import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function ServiceNotificationForm({ register, errors, setValue, businessScope }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
					<FormatVersionSelect 
						keyword="serviceNotification"
						setValue={setValue}
						businessScope={businessScope}
					/>
					{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
					<Form.Control {...register('expire_at')} disabled />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Fecha Envío Notificación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('notified_at', { required: true })}
					type="date"
				/>
				{ errors.notified_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Coordinador de Programación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('sign_by', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.sign_by && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ businessScope === 'pruebas' && (
				<Form.Group>
					<Form.Label>Observaciones <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('observations', { required: true })}
						style={{ height: '200px' }}
						defaultValue={`- El tiempo programado para la ejecución de la inspección está determinado por el número de tanques y/o tuberías y las especificaciones, es un tiempo aproximado el cual se detallará en el Plan de inspección enviado por DEWAR previo al día de la actividad de inspección.\n- Durante la prueba de hermeticidad el tanque se encontrará fuera de funcionamiento mínimo 2 horas aproximadamente. \n- Es requerido que el Cliente tenga disponible personal encargado de atender la inspección, durante todo el tiempo del proceso y autorizado para el manejo de los ítems a inspeccionar, así como suministrar información y documentación requerida para la inspección.\n- Sí se cuenta con las recomendaciones del fabricante para el procedimiento de la prueba, este soporte debe presentarse el día de la inspección, en caso de que este valor NO garantice la seguridad y la integridad del tanque y/o tubería a inspeccionar, DEWAR podrá abstenerse de realizar la prueba o se realizará con los criterios propios establecidos en caso de que el CLIENTE lo apruebe.\n-Es preferible que los tanques a inspeccionar deben estar preparados para ser inspeccionados, se verifica el taponamiento de bocas del tanque y tuberías, incluyendo el desfogue. \n- Por favor informarnos de requerir una hora específica para el inicio, de acuerdo con el tiempo aproximado necesario para la realización del servicio, de presentarse inconvenientes o retrasos por parte del Cliente, se generarán un cargo adicional de 150.000 pesos m/cte. \n- A esta notificación se adjunta el Anexo 1. Indicaciones Proceso de Hermeticidad para que por favor lo tenga en cuenta.`}
						placeholder='Escriba aquí...'
					/>
				</Form.Group>
			)}
		</React.Fragment>
	)
}