import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Alert } from 'antd'

import { updateServiceReport } from '../../services'
import { handleError } from '../../../../helpers'

export default function ReportInspectorResultModal({ visible, onClose, serviceReport, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm()

	const onSubmit = values => {
		updateServiceReport(serviceReport.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultado Final de la Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Resultado Final Inspector <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register('is_inspector_compliant', { required: serviceReport.is_inspector_compliant !== null ? false : true })}
								defaultValue={serviceReport && serviceReport.is_inspector_compliant ? 1 : 0}
								disabled={serviceReport && serviceReport.is_inspector_compliant !== null}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option value="1">Conforme</option>
								<option value="0">No Conforme</option>
							</Form.Control>
							{ errors.is_inspector_compliant && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Observaciones Adicionales <small>(opcional)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('inspector_observations')}
								defaultValue={serviceReport && serviceReport.inspector_observations}
								placeholder='Escriba aquí...'
								style={{ height: '70px' }}
							/>
						</Form.Group>
						<Alert type="warning" className='mt-20 mb-20' description="Una vez register su decisión únicamente podrá modificar las observaciones." />
						<Button color="primary" type="submit">Actualizar Resultado Final</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}