import React, { useState, useEffect }  from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ReviewLogbookDocument from '../ReviewLogbooks/partials/ReviewLogbookDocument'

export default function ReviewLogbookPreviewOffline(props) {
	const { isConnected } = useSelector(state => state.offline)
	const reviewLogbookId = props.id ? props.id : props.match.params.id
	const serviceId = props.serviceId ? props.serviceId : props.match.params.serviceId
	const services = useSelector(state => state.services.data)
	const [reviewLogbook, setReviewLogbook] = useState(null)

	const service = services.filter(s => s.id === parseInt(serviceId))[0]

	useEffect(() => {
		if(!service) return message.error('El servicio solicitado no se encuentra disponible Offline')
		let currentReviewLogbook = service.service_inspector.service_plan.service_review.review_logbooks.filter(r => r.id === parseInt(reviewLogbookId))[0]
		setReviewLogbook({
			...currentReviewLogbook,
			service_review: {
				...service.service_inspector.service_plan.service_review,
				service_plan: {
					...service.service_inspector.service_plan,
					service_inspector: {
						...service.service_inspector,
						service
					}
				}
			}
		})
	}, [reviewLogbookId, service])

	if(isConnected) return <Redirect to={`/review_logbooks/preview/${reviewLogbookId}`} />

	if(!reviewLogbook) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ReviewLogbookDocument reviewLogbook={reviewLogbook} />
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`