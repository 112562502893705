import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Redirect } from 'react-router'

import ListTopBar from '../../components/ListTopBar'
import ServicesTable from './partials/ServicesTable'
import NewServiceModal from './partials/NewServiceModal'

import { getServices } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ServicesList({ businessScope }) {
	const { isConnected } = useSelector(state => state.offline)
	const user = useSelector(state => state.auth.user)
	const [services, setServices] = useState(null)
	const [filterType, setFilterType] = useState('proposal.inquiry.branchSpec.branch.name')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!services && isConnected && getServices({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'proposal.inquiry.branchSpec.branch.company,serviceInspector.user,proposal.formatVersion',
			'filter[serviceInspector.user.id]': user.role.includes('inspector') ? user.id : null,
			'filter[proposal.formatVersion.business_scope]': businessScope,
			sort: '-start_at'
		})
			.then(res => {
				setServices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [services, filterType, filterValue, user, pagination, isConnected, businessScope])

	useEffect(() => setServices(null), [businessScope])

	if(!isConnected) return <Redirect to={`/offline/services/${businessScope}`} />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Servicios de ${businessScope === 'pruebas' ? 'Pruebas' : 'Inspección'}`}
				subtitle="Plataforma DEWAR"
				reload={() => setServices(null)}
				options={[
					{ 
						label: 'Buscar por empresa', 
						value: 'proposal.inquiry.branchSpec.branch.company.name'
					},
					{
						label: 'Buscar por nombre de instalación',
						value: 'proposal.inquiry.branchSpec.branch.name'
					},
					{
						label: 'Buscar número de consecutivo propuesta',
						value: 'proposal.sku_key'
					},
					{
						label: 'Buscar por nombre de inspector asignado',
						value: 'serviceInspector.user.name'
					},
					{
						label: 'Buscar por tipo de instalación',
						value: 'proposal.inquiry.branchSpec.branch.instalationType.name'
					},
					{
						label: 'Buscar por consecutivo del servicio (ID)',
						value: 'id'
					}
				]}
				ctaText={user.role === 'admin' ? "Iniciar Nuevo Servicio" : null}
				handleCtaClick={user.role === 'admin' ? () => setShowNewModal(true) : null}
			/>
			<Card>
				<CardBody>
					<ServicesTable 
						services={services}
						reload={() => setServices(null)}
						pagination={pagination}
						setPagination={setPagination}
						businessScope={businessScope}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewServiceModal 
					visible
					onClose={() => setShowNewModal(false)}
					businessScope={businessScope}
				/>
			) }	
		</React.Fragment>
	)
}