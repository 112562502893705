import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServiceReportDocument from './partials/ServiceReportDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServiceReport } from './services'
import { handleError } from '../../helpers'

export default function ServiceReportPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [serviceReport, setServiceReport] = useState(null)

	useEffect(() => {
		!serviceReport && isConnected && publicGetServiceReport(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,reportEvidence,serviceReview.servicePlan.serviceInspector.user,serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.employee,serviceReview.tankSpecs,serviceReview.islandSpecs,serviceReview.reviewEquipments,serviceReview.nonCompliantReport,formatVersion,serviceReview.reviewLogbooks',
			append: 'initial_inspection_at,retry_inspection_at,total_ncp_tasks,total_nc_tasks,total_resolved_tasks'
		})
			.then(res => setServiceReport(res.data.data))
			.catch(error => handleError(error))
	}, [serviceReport, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!serviceReport) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServiceReportDocument serviceReport={serviceReport} />
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`