import dewarApi, { getDomain } from '../../services/dewarApi'

export const getEmployees = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/employees', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEmployee = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/employees/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEmployee = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/employees', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEmployee = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/employees/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEmployee = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/employees/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEmployees = async (name, token, scopeId) => (
	fetch( getDomain()+`/employees?filter[name]=${encodeURI(name)}&filter[company_id]=${scopeId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(employee => ({
				label: employee.name,
				value: employee.id,
			})),
		)
)