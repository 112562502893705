import React, { useState } from 'react'
import { Table, Tooltip, Divider, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faImages } from '@fortawesome/free-solid-svg-icons'

import EvidenceAttachmentsModal from './EvidenceAttachmentsModal'
import NewEvidenceDragAndDrop from './NewEvidenceDragAndDrop'
import CloneFilesFromItemModal from './CloneFilesFromItemModal'

export default function ServiceEvidenceTable({ 
	evidenceTemplates, evidence, reload, reloadEvidence, verificationListId 
}) {
	const [attachmentsModal, setAttachmentsModal] = useState(null)
	const [cloneFromItemModal, setCloneFromItemModal] = useState(null)

	const columns = [
		{
			title: 'No Evidencia',
			dataIndex: 'item',
		},
		{
			title: 'Descripción',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			width: '130px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Adjuntos Subidos" onClick={() => setAttachmentsModal(r)}>
						<FontAwesomeIcon icon={faImages} className='text-link' />
						{" "}<span className='text-link'>Ver Adjuntos</span>
					</Tooltip>
					<Divider type="horizontal" className='my-10' />
					<Tooltip title="Clonar desde otro item" onClick={() => setCloneFromItemModal(r)}>
						<FontAwesomeIcon icon={faCopy} className='text-link' />
						{" "}<span className='text-link'>Clonar Desde</span>
					</Tooltip>
					<Divider type="horizontal" className='my-10' />
					<p className='mb-0'>Total subidos: <b>{evidence ? evidence.filter(e => e.evidence_template_id === r.id).length : ''}</b></p>
				</React.Fragment>
			)
		},
		{
			title: 'Subir Nuevo',
			width: '200px',
			render: (t, r) => (
				<NewEvidenceDragAndDrop 
					evidenceTemplate={r}
					reloadEvidence={reloadEvidence}
					verificationListId={verificationListId}
				/>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={evidenceTemplates}
				columns={columns}
				rowKey={r => r.id}
				className='mt-10'
				size="small"
				locale={{ emptyText: <Empty description="No se encontraron registros" imageStyle={{ height: '70px' }} /> }}
				loading={!evidenceTemplates}
			/>
			{ attachmentsModal && evidence && (
				<EvidenceAttachmentsModal 
					visible
					onClose={() => setAttachmentsModal(null)}
					evidenceTemplate={attachmentsModal}
					evidence={evidence}
					reloadEvidence={reloadEvidence}
					evidenceTemplates={evidenceTemplates}
				/>
			)}
			{ cloneFromItemModal && evidence && (
				<CloneFilesFromItemModal 
					visible
					onClose={() => setCloneFromItemModal(null)}
					evidenceTemplate={cloneFromItemModal}
					evidence={evidence}
					reloadEvidence={reloadEvidence}
					evidenceTemplates={evidenceTemplates}
				/>
			)}
		</React.Fragment>
	)
}