import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Divider } from 'antd'
import { Badge, Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

import NewServiceReportModal from '../../ServiceReports/partials/NewServiceReportModal'
import EditServiceModal from './EditServiceModal'
import NewRetryServiceModal from './NewRetryServiceModal'

import ServiceOfflineStatus from '../../Offline/partials/ServiceOfflineStatus'

export default function ServiceProfileTopBar({ service, reload, setCurrentTab }) {
	const user = useSelector(state => state.auth.user)
	const [showNewRetryModal, setShowNewRetryModal] = useState(false)
	const [showNewReportModal, setShowNewReportModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					{ service.business_scope === 'inspeccion' ? (
						<React.Fragment>
							<div className='float-right pt-10'>
								{ service.canceled_at && <Badge color="danger" className='mr-10'>Servicio Cancelado</Badge>}
								<ServiceOfflineStatus service={service} />
								<Divider type="vertical" />
								{ (!service.service_inspector || !service.service_inspector.service_plan) && (
									<Button color="primary" outline onClick={() => setShowEditModal(true)}>
										Editar Solicitud
									</Button>
								)}
								{ ( service.status.includes('_retry_needed') && user.role === 'admin' ) && (
									<React.Fragment>
										{ service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.service_inspector.service_plan.service_review.retry_notice && (
											<Button color="primary" onClick={() => setShowNewRetryModal(true)} className="ml-10">
												Abrir Inspección de Cierre
											</Button>
										)}
										<Divider type='vertical' />
										<Button color='primary' className='ml-10' onClick={() => setShowNewReportModal(true)}>
											Generar Informe de Inspección
										</Button>
									</React.Fragment>
								)}
								{ service.initial_service_id && (
									<Link to={`/services/${service.initial_service_id}`} onClick={reload}>
										<Button color='primary' outline className='ml-10'>
											Ver Inspección Inicial
										</Button>
									</Link>
								)}
								{ service.retry_service && (
									<Link to={`/services/${service.retry_service.id}`} onClick={reload}>
										<Button color='primary' outline className='ml-10'>
											Ver Inspección de Cierre
										</Button>
									</Link>
								)}
								{ service.status.includes('_report_needed') && (
									<Button color='primary' className='ml-10' onClick={() => setShowNewReportModal(true)}>
										Generar Informe de Inspección
									</Button>
								)}
								{ service.service_report_id && (
									<Link to={`/service_reports/${service.service_report_id}`} onClick={reload}>
										<Button color='primary' outline className='ml-10'>
											Ver Informe de Inspección
										</Button>
									</Link>
								)}
							</div>
							<CardTitle>Servicio de Inspección #{service.id}</CardTitle>
							<CardSubtitle>Propuesta: {service.proposal.sku} | Inspección {service.type}</CardSubtitle>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className='float-right pt-10'>
								{ service.canceled_at && <Badge color="danger" className='mr-10'>Servicio Cancelado</Badge>}
								<ServiceOfflineStatus service={service} />
								<Divider type="vertical" />
								{ (!service.service_inspector || !service.service_inspector.service_plan) && (
									<Button color="primary" outline onClick={() => setShowEditModal(true)}>
										Editar Solicitud
									</Button>
								)}
								{ ( (user.role === 'admin' || user.role.includes('director')) && service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && !service.service_report_id ) && (
									<Button color='primary' className='ml-10' onClick={() => setShowNewReportModal(true)}>
										Generar Informe de Inspección
									</Button>
								)}
								{ service.service_report_id && (
									<Link to={`/service_reports/${service.service_report_id}`} onClick={reload}>
										<Button color='primary' outline className='ml-10'>
											Ver Informe de Inspección
										</Button>
									</Link>
								)}
							</div>
							<CardTitle>Servicio de Pruebas #{service.id}</CardTitle>
							<CardSubtitle>Propuesta: {service.proposal.sku} | Servicio {service.proposal.inquiry.test_details.service_name}</CardSubtitle>
						</React.Fragment>
					)}					
				</CardBody>
			</Card>
			{ showNewRetryModal && (
				<NewRetryServiceModal
					visible
					onClose={() => setShowNewRetryModal(false)} 
					service={service}
					reload={reload}
					setCurrentTab={setCurrentTab}
				/>
			)}
			{ showNewReportModal && (
				<NewServiceReportModal 
					visible
					onClose={() => setShowNewReportModal(false)}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
					businessScope={service.business_scope}
				/>
			)}
			{ showEditModal && (
				<EditServiceModal 
					visible
					onClose={() => setShowEditModal(false)}
					service={service}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}