import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faIndustry, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'reactstrap'

import EditCompanyModal from './EditCompanyModal'
import CompanyPortalAccessModal from './CompanyPortalAccessModal'
import CompanyEmployeesModal from '../../Employees/CompanyEmployeesModal'
import CompanyBranchesModal from '../../Branches/CompanyBranchesModal'
import CompanyEmailsModal from '../../CompanyEmails/CompanyEmailsModal'

import Pagination from '../../../components/Pagination'

import { deleteCompany } from '../services'
import { handleError } from '../../../helpers'

export default function CompaniesTable({ companies, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editCompany, setEditCompany] = useState(null)
	const [companyEmployees, setCompanyEmployees] = useState(null)
	const [companyBranches, setCompanyBranches] = useState(null)
	const [companyEmails, setCompanyEmails] = useState(null)
	const [companyPortalAccess, setCompanyPortalAccess] = useState(null)

	const columns = [
		{
			title: 'Empresa',
			dataIndex: 'name'
		},
		{
			title: 'Documento',
			dataIndex: 'complete_document'
		},
		{
			title: 'Celular',
			dataIndex: 'mobile'
		},
		{
			title: 'Teléfono',
			dataIndex: 'phone'
		},
		{
			title: 'Correo',
			dataIndex: 'email'
		},
		{
			title: 'Portal Clientes',
			dataIndex: 'portal_is_active',
			render: (t, r) => (
				<Badge
					color={t ? 'success' : 'dark'}
					onClick={()=> currentUser.role === 'admin' ? setCompanyPortalAccess(r) : message.warning('Funcionalidad únicamente habilitada para administradores')}
					style={{ cursor: 'pointer' }}
				>
					{t ? 'Con acceso' : 'Sin acceso'}
				</Badge>
			)
		},
		{
			title: 'Acciones',
			width: '140px',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditCompany(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Personal">
						<div className='inline text-link' onClick={()=>setCompanyEmployees(record)}>
							<FontAwesomeIcon icon={faUsers} />{" "}
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Instalaciones">
						<div className='inline text-link' onClick={()=>setCompanyBranches(record)}>
							<FontAwesomeIcon icon={faIndustry} />{" "}
						</div>
					</Tooltip>					
					{/* <Divider type="vertical" /> */}
					{/* <Tooltip title="Correos Electrónicos">
						<div className='inline text-link' onClick={()=>setCompanyEmails(record)}>
							<FontAwesomeIcon icon={faMailBulk} />{" "}
						</div>
					</Tooltip> */}
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el cliente?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteCompany(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const expandedRowRender = record => (
		<React.Fragment>
			<p className='mb-5'><strong>Dirección: </strong>{record.address_line}, {record.address_city}, {record.address_state}</p>
			<p className='mb-5'><strong>Correo Electrónico Facturación: </strong>{record.invoicing_email}</p>
			<p className='mb-0'><strong>Dirección Fisica Envío Certificado: </strong>{record.certificate_address_line}</p>
			<p className='mb-5'><strong>Correo Electrónico #2: </strong>{record.email_secundary}</p>
			<p className='mb-5'><strong>Correo Electrónico #3: </strong>{record.email_terciary}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={companies}
				rowKey={r => r.id}
				columns={columns}
				size="small"
				expandable={{ expandedRowRender }}
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editCompany && (
				<EditCompanyModal 
					visible
					onClose={() => setEditCompany(null)}
					company={editCompany}
					reload={reload}
				/>
			)}
			{ companyEmployees && (
				<CompanyEmployeesModal 
					visible
					onClose={() => setCompanyEmployees(null)}
					company={companyEmployees}
				/>
			)}
			{ companyBranches && (
				<CompanyBranchesModal 
					visible
					onClose={() => setCompanyBranches(null)}
					company={companyBranches}
				/>
			)}
			{ companyEmails && (
				<CompanyEmailsModal 
					visible
					onClose={() => setCompanyEmails(null)}
					company={companyEmails}
				/>
			)}
			{ companyPortalAccess && (
				<CompanyPortalAccessModal 
					visible
					onClose={() => setCompanyPortalAccess(null)}
					reload={reload}
					company={companyPortalAccess}
				/>
			)}
		</React.Fragment>
	)
}