import React from 'react'
import { Form } from 'react-bootstrap'

export default function ReviewLogbookDirectorVeredictForm({ register, errors, watch, specType }) {
    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>Revisión y Decisión del Director Técnico</Form.Label>
                <Form.Control
                    as="select"
                    {...register('requirements_survey.is_director_compliant', {})}
                >
                    <option value="">:: Selecciona una opción ::</option>
                    <option>Conforme</option>
                    <option>No Conforme</option>
                </Form.Control>
            </Form.Group>
        </React.Fragment>
    )
}