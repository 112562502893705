import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'

export default function SERVICE_VEREDICT_TEMPLATE_INS01({ data }) {
	const proposal = data.service_report.service_review.service_plan.service_inspector.service.proposal
	const branch = proposal.inquiry.branch_spec.branch

	return (
		<Document>
			<DocumentHeader 
				title="REVISIÓN Y DECISIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				logoSrc={logo}
			/>
			<p className='section_title'>1. CONFORMACIÓN COMITÉ TÉCNICO</p>
			<table className='table border'>
				<tbody>
					<tr>
						<td className='bg-light bold'>Inspector(a) que presenta el proceso:</td>
						<td className='px-20'>{data.service_report.service_review.service_plan.service_inspector.user.name}</td>
						<td className='bg-light bold'>Consecutivo Comité Técnico:</td>
						<td className='px-20'>{data.service_report.committee_sku_formated}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Director (a) Técnico (a) miembro Comité Técnico:</td>
						<td className='px-20'>{data.service_report.director_name}</td>
						<td className='bg-light bold'>Fecha del Comité Técnico:</td>
						<td className='px-20'>{moment(data.service_report.committee_at).format('DD-MMM-YYYY').toUpperCase()}</td>
					</tr>
					<tr>
						<td className='bg-light' colSpan={4}>Si para el Presente Comité se da la participación de un inspector adicional convocado se incluye en las observaciones del punto 5 con su correspondiente anexo.</td>
					</tr>
				</tbody>
			</table>
			<p className='section_title'>2. INFORMACIÓN DE LA INSTALACIÓN</p>
			<table className='table border'>
				<tbody>
					<tr>
						<td className='bg-light bold'>Nombre de Instalación:</td>
						<td colSpan={4}>{branch.name}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Tipo de Instalación:</td>
						<td colSpan={2}>{branch.instalation_type_name}</td>
						<td className='bg-light bold'>Código del Proceso:</td>
						<td className='px-20'>{proposal.sku}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Proceso de Inspección*:</td>
						<td className='bg-light bold'>Inicial</td>
						<td className='px-20'>{data.initial_inspection_at ? moment(data.initial_inspection_at).format('DD-MMM-YYYY').toUpperCase() : ''}</td>
						<td className='bg-light bold'>Cierre</td>
						<td className='px-20'>{ data.retry_inspection_at ? moment(data.retry_inspection_at).format('DD-MMM-YYYY').toUpperCase() : 'NA' }</td>
					</tr>
				</tbody>
			</table>
			<small>*Se pueden marcar las dos opciones cuando se da proceso tiene inspección inicial y cierre en el mismo proceso.</small>
			<p className='section_title'>
				3. DECLARACIÓN DE IMPARCIALIDAD, INDEPENDENCIA, CONFIDENCIALIDAD Y EXONERACIÓN DE CONFLICTO DE INTERÉS
			</p>
			<p>En calidad miembro del Comité Técnico, declaro que en un lapso no inferior a los (2) años previos a la realización de la presente revisión, NO he tenido relación comercial y/o he realizado actividades de:</p>
			<ul>
				<li>Diseño o suministro de productos a la instalación a verificar</li>
				<li>Aconsejar o brindar servicios de consultoría a los solicitantes, en aspectos relacionados con la inspección solicitada</li>
				<li>Suministrar cualquier otro producto o servicio que pueda comprometer la confidencialidad, objetividad o imparcialidad de su proceso de inspección y decisiones</li>
			</ul>
			<p>También declaro que en la participación del presenté Comité manejaré la información observada en la instalación de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo de Inspección DEWAR S.A.S., para el desarrollo del Comité técnico tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento.</p>
			<p>Así mismo, declaró no presentar conflicto de interés con las instalaciones relacionadas para las cuales he sido convocado como miembro del Comité técnico, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como organismo de inspección, y el Reglamento de inspección.</p>
			<p>Por último, declaro revisar y decidir fuera de presiones de alguna índole, y las decisiones tomadas corresponde netamente a mi competencia profesional guiadas por el cumplimiento del Reglamento Técnico vigente objeto del proceso de inspección.</p>
			<p className='section_title'>4. REGISTROS DEL PROCESO A REVISAR Y DECIDIR</p>
			<p>Todos los registros del proceso de inspección para la revisión y decisión se encuentran en el expediente del proceso del Programa de SIDIN – Sistema de información DEWAR organismo de inspección. Cuyos participantes del Comité tienen el ingreso autorizado a esta plataforma.</p>
			<p className='section_title'>5. REVISIÓN Y OBSERVACIONES DEL COMITÉ TÉCNICO</p>
			<table className='table text-center border'>
				<tbody>
					<tr>
						<td className='bg-light bold'>Observaciones Director (a) Técnico (a) como miembro del Comité Técnico:</td>
					</tr>
					<tr>
						<td style={{ padding: '10px', minHeight: '100px' }} className='text-left'>
							{data.service_report.director_observations}
						</td>
					</tr>
				</tbody>
			</table>
			<p className='section_title'>6. DECISIÓN COMITÉ TÉCNICO</p>
			<table className='table border mb-20'>
				<tbody>
					<tr className='bg-light text-center bold'>
						<td>Decisión Inspector (a)</td>
						<td>Decisión Director (a) Técnico (a):</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width mt-10 mb-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.service_review.is_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.service_review.is_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_director_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_director_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colSpan={2} className='bg-light'>Si la decisión es no otorgar por favor exponer las razones correspondientes / Si existe participación de un inspector convocado como experto dejar anotado su posición y decisión frente al proceso:</td>
					</tr>
					<tr>
						<td colSpan={2} style={{ padding: '40px', minHeight: '100px' }}>
							{data.service_report.committee_observations ? data.service_report.committee_observations : <em>Sin observaciones</em>}
						</td>
					</tr>
				</tbody>
			</table>
			<table className='table border'>
				<tbody className='text-center'>
					<tr>
						<td className='bg-light bold'>Decisión del Comité Técnico:</td>
					</tr>
					<tr>
						<td>Teniendo en cuenta lo anterior el Comité Técnico decide que la Instalación objeto del proceso de inspección es:</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Conforme</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Conforme</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td>Por lo tanto, el Comité de técnico en pleno ejercicio de las funciones establecidas en el procedimiento de revisión y decisión y declarando, no haber recibido ningún tipo de presión y haber sido: imparcial e independiente en la toma de decisión, decide sobre el certificado de inspección a la Instalación de referencia:</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 30px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`